import React, { Component, useState, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { colors } from "../../Helpers/ColorsPalette";
import { Modal } from "react-responsive-modal";
import "../../Helpers/customModalcss.css";

const Drawer = () => {
  let history = useHistory();
  let location = useLocation();

  const userString = localStorage.getItem("user");
  const userObject = JSON.parse(userString);

  const [activeItem, setActiveItem] = useState("");
  const [collapse, setCollapse] = useState("");
  const [sessionExpired, setSessionExpired] = useState(true);
  const [user, setUser] = useState("");

  useEffect(() => {
    setUser(userObject);
  }, []);

  useEffect(() => {
    return () => {};
  }, [user, NavLink]);

  return (
    <div
      id="sidebar"
      className="active"
      style={{ backgroundColor: colors.primary_blue, fontFamily: "nunito" }}
    >
      <div className="sidebar-wrapper active">
        <div className="sidebar-header position-relative">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo d-flex justify-content-center align-items-center ">
              <a href="/dashboard"></a>
            </div>
            <div className="sidebar-toggler x">
              <a href="#" className="sidebar-hide d-xl-none d-block">
                <i className="bi bi-x bi-middle" />
              </a>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img style={{ width: "30%" }} src="/logo.png" alt="QuickPower" />
          </div>
          <ul className="menu">
            <li className="sidebar-title">Menu</li>
            <li className="sidebar-item ">
              <NavLink
                style={{
                  backgroundColor:
                    location.pathname == "/dashboard"
                      ? colors.primary_blue
                      : null,
                  color: location.pathname == "/dashboard" ? "white" : null,
                }}
                to="/dashboard"
                className="sidebar-link"
              >
                <i
                  style={{
                    color: location.pathname == "/dashboard" ? "white" : null,
                  }}
                  className="bi bi-grid-fill"
                />
                <span>Dashboard</span>
              </NavLink>
            </li>

            {userObject.granted_permissions_array.indexOf("category_view") >
              -1 ===
            true ? (
              <li className="sidebar-item ">
                <NavLink
                  style={{
                    backgroundColor:
                      location.pathname == "/categories" ||
                      location.pathname == "/categories/add" ||
                      location.pathname == "/categories/edit" ||
                      location.pathname == "/categories/view"
                        ? colors.primary_blue
                        : null,
                    color:
                      location.pathname == "/categories" ||
                      location.pathname == "/categories/add" ||
                      location.pathname == "/categories/edit" ||
                      location.pathname == "/categories/view"
                        ? "white"
                        : null,
                  }}
                  to="/categories"
                  className="sidebar-link"
                >
                  <i
                    style={{
                      color:
                        location.pathname == "/categories" ||
                        location.pathname == "/categories/add" ||
                        location.pathname == "/categories/edit" ||
                        location.pathname == "/categories/view"
                          ? "white"
                          : null,
                    }}
                    className="bi bi-person-badge"
                  />
                  <span>Categories</span>
                </NavLink>
              </li>
            ) : null}

            {userObject.granted_permissions_array.indexOf(
              "departmentType_view"
            ) >
              -1 ===
            true ? (
              <li className="sidebar-item ">
                <NavLink
                  style={{
                    backgroundColor:
                      location.pathname == "/departmentType" ||
                      location.pathname == "/departmentType/add" ||
                      location.pathname == "/departmentType/edit" ||
                      location.pathname == "/departmentType/view"
                        ? colors.primary_blue
                        : null,
                    color:
                      location.pathname == "/departmentType" ||
                      location.pathname == "/departmentType/add" ||
                      location.pathname == "/departmentType/edit" ||
                      location.pathname == "/departmentType/view"
                        ? "white"
                        : null,
                  }}
                  to="/departmentType"
                  className="sidebar-link"
                >
                  <i
                    style={{
                      color:
                        location.pathname == "/departmentType" ||
                        location.pathname == "/departmentType/add" ||
                        location.pathname == "/departmentType/edit" ||
                        location.pathname == "/departmentType/view"
                          ? "white"
                          : null,
                    }}
                    className="bi bi-person-badge"
                  />
                  <span>Department Types</span>
                </NavLink>
              </li>
            ) : null}

            {userObject.granted_permissions_array.indexOf("fuelStation_view") >
              -1 ===
            true ? (
              <li className="sidebar-item ">
                <NavLink
                  style={{
                    backgroundColor:
                      location.pathname == "/fuelstations" ||
                      location.pathname == "/fuelstations/add" ||
                      location.pathname == "/fuelstations/edit" ||
                      location.pathname == "/fuelstations/view"
                        ? colors.primary_blue
                        : null,
                    color:
                      location.pathname == "/fuelstations" ||
                      location.pathname == "/fuelstations/add" ||
                      location.pathname == "/fuelstations/edit" ||
                      location.pathname == "/fuelstations/view"
                        ? "white"
                        : null,
                  }}
                  to="/fuelstations"
                  className="sidebar-link"
                >
                  <i
                    style={{
                      color:
                        location.pathname == "/fuelstations" ||
                        location.pathname == "/fuelstations/add" ||
                        location.pathname == "/fuelstations/edit" ||
                        location.pathname == "/fuelstations/view"
                          ? "white"
                          : null,
                    }}
                    className="bi bi-person-badge"
                  />
                  <span>Fuel Stations</span>
                </NavLink>
              </li>
            ) : null}

            {userObject.granted_permissions_array.indexOf("complaint_view") >
              -1 ===
            true ? (
              <li className="sidebar-item ">
                <NavLink
                  style={{
                    backgroundColor:
                      location.pathname == "/complaint" ||
                      location.pathname == "/complaint/view"
                        ? colors.primary_blue
                        : null,
                    color:
                      location.pathname == "/complaint" ||
                      location.pathname == "/complaint/view"
                        ? "white"
                        : null,
                  }}
                  to="/complaint"
                  className="sidebar-link"
                >
                  <i
                    style={{
                      color:
                        location.pathname == "/complaint" ||
                        location.pathname == "/complaint/view"
                          ? "white"
                          : null,
                    }}
                    className="bi bi-person-badge"
                  />
                  <span>Complaints</span>
                </NavLink>
              </li>
            ) : null}

            <li className="sidebar-title">Settings</li>

            {/* {userObject.granted_permissions_array.indexOf("user_view") > -1 ===
            true ? ( */}
              <li className="sidebar-item ">
                <NavLink
                  style={{
                    backgroundColor:
                      location.pathname == "/users" ||
                      location.pathname == "/users/add" ||
                      location.pathname == "/users/edit" ||
                      location.pathname == "/users/view"
                        ? colors.primary_blue
                        : null,
                    color:
                      location.pathname == "/users" ||
                      location.pathname == "/users/add" ||
                      location.pathname == "/users/edit" ||
                      location.pathname == "/users/view"
                        ? "white"
                        : null,
                  }}
                  to="/users"
                  className="sidebar-link"
                >
                  <i
                    style={{
                      color:
                        location.pathname == "/users" ||
                        location.pathname == "/users/add" ||
                        location.pathname == "/users/edit" ||
                        location.pathname == "/users/view"
                          ? "white"
                          : null,
                    }}
                    className="bi bi-person-badge"
                  />
                  <span>Users</span>
                </NavLink>
              </li>
            {/* ) : null} */}

            {userObject.granted_permissions_array.indexOf("role_view") > -1 ===
            true ? (
              <li className="sidebar-item ">
                <NavLink
                  style={{
                    backgroundColor:
                      location.pathname == "/roles" ||
                      location.pathname == "/roles/add" ||
                      location.pathname == "/roles/edit" ||
                      location.pathname == "/roles/view"
                        ? colors.primary_blue
                        : null,
                    color:
                      location.pathname == "/roles" ||
                      location.pathname == "/roles/add" ||
                      location.pathname == "/roles/edit" ||
                      location.pathname == "/roles/view"
                        ? "white"
                        : null,
                  }}
                  to="/roles"
                  className="sidebar-link"
                >
                  <i
                    style={{
                      color:
                        location.pathname == "/roles" ||
                        location.pathname == "/roles/add" ||
                        location.pathname == "/roles/edit" ||
                        location.pathname == "/roles/view"
                          ? "white"
                          : null,
                    }}
                    className="bi bi-person-badge"
                  />
                  <span>Roles</span>
                </NavLink>
              </li>
            ) : null}

            {userObject.granted_permissions_array.indexOf("report_view") >
              -1 ===
            true ? (
              <li className="sidebar-item has-sub">
                <a
                  onClick={() => {
                    if (collapse == "reports") {
                      setCollapse("");
                    } else {
                      setCollapse("reports");
                    }
                  }}
                  style={{
                    backgroundColor:
                      location.pathname == "/complaintsreport" ||
                      location.pathname == "/categoryreports" ||
                      location.pathname == "/fsexpensereport" ||
                      location.pathname == "/categoryexpensereport"
                        ? colors.primary_blue
                        : null,
                    color:
                      location.pathname == "/complaintsreport" ||
                      location.pathname == "/categoryreports" ||
                      location.pathname == "/fsexpensereport" ||
                      location.pathname == "/categoryexpensereport"
                        ? "white"
                        : null,
                  }}
                  href="#"
                  className="sidebar-link"
                >
                  <i
                    style={{
                      color:
                        location.pathname == "/complaintsreport" ||
                        location.pathname == "/categoryreports" ||
                        location.pathname == "/fsexpensereport" ||
                        location.pathname == "/categoryexpensereport"
                          ? "white"
                          : null,
                    }}
                    className="bi bi-clipboard-minus"
                  />
                  <span>Reportings</span>
                </a>
                <ul
                  style={{ display: collapse == "reports" ? "block" : "none" }}
                  className="submenu"
                >
                  <li className="submenu-item">
                    <NavLink
                      style={{
                        textAlign: "left",
                        paddingLeft: 23,
                        textDecoration: "none",
                        color:
                          location.pathname == "/complaintsreport"
                            ? colors.primary_blue
                            : null,
                      }}
                      to="/complaintsreport"
                    >
                      Complaints Report
                    </NavLink>
                  </li>
                  <li className="submenu-item">
                    <NavLink
                      style={{
                        textAlign: "left",
                        paddingLeft: 23,
                        textDecoration: "none",
                        color:
                          location.pathname == "/categoryreports"
                            ? colors.primary_blue
                            : null,
                      }}
                      to="/categoryreports"
                    >
                      Category Report
                    </NavLink>
                  </li>
                  <li className="submenu-item">
                    <NavLink
                      style={{
                        textAlign: "left",
                        paddingLeft: 23,
                        textDecoration: "none",
                        color:
                          location.pathname == "/fsexpensereport"
                            ? colors.primary_blue
                            : null,
                      }}
                      to="/fsexpensereport"
                    >
                      FS Expense Report
                    </NavLink>
                  </li>
                  <li className="submenu-item">
                    <NavLink
                      style={{
                        textAlign: "left",
                        paddingLeft: 23,
                        textDecoration: "none",
                        color:
                          location.pathname == "/categoryexpensereport"
                            ? colors.primary_blue
                            : null,
                      }}
                      to="/categoryexpensereport"
                    >
                      Category Expense
                    </NavLink>
                  </li>
                </ul>
              </li>
            ) : null}

            <li className="sidebar-item has-sub">
              <a
                onClick={() => {
                  if (collapse == "profile") {
                    setCollapse("");
                  } else {
                    setCollapse("profile");
                  }
                }}
                style={{
                  backgroundColor:
                    location.pathname == "/userprofile"
                      ? colors.primary_blue
                      : null,
                  color: location.pathname == "/userprofile" ? "white" : null,
                }}
                href="#"
                className="sidebar-link"
              >
                <i
                  style={{
                    color: location.pathname == "/userprofile" ? "white" : null,
                  }}
                  className="bi bi-gear-wide"
                />
                <span>Settings</span>
              </a>
              <ul
                style={{ display: collapse == "profile" ? "block" : "none" }}
                className="submenu"
              >
                <li className="submenu-item">
                  <NavLink
                    style={{
                      textAlign: "left",
                      paddingLeft: 23,
                      textDecoration: "none",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.clear();
                      history.push("/login");
                      history.go(0);
                    }}
                    to="#"
                  >
                    Logout
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
