import React from "react";
//import pagenotfound image
import notAuthorised from "./NotAuthorised.png";
//react router dom
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";

const NotAuthorised = () => {
  return (
    <Router>
      <Switch>
        <div
          className="pageNotFound"
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            padding: "10%",
          }}
        >
          <img
            src={notAuthorised}
            style={{ width: "30%", height: "auto", textAlign: "center" }}
            alt="not found"
          />
        </div>
      </Switch>
    </Router>
  );
};

export default NotAuthorised;
