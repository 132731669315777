import { Fragment, React, useEffect, useState } from "react";
import { Formik } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Loader from "../../Loader/Loader";
import Loaderr from "react-loader-spinner";
import * as Yup from "yup";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { colors } from "../../Helpers/ColorsPalette";

const AddRole = () => {
  let history = useHistory();
  const [token, setToken] = useState(null);
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [roleName, setRoleName] = useState("");
  const [userTypes, setUserTypes] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [modules, setModules] = useState([]);
  const selectedPermissions = new Array();
  const [responseJson, setResponseJson] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");

    setToken("Bearer " + token);

    //Status API
    fetch(AdminGlobal.ROLEPREREQ, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const perm = responseJson.permissions.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        const statuses = responseJson.statuses.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        const user_types = responseJson.user_types.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setStatus(statuses);
        setUserTypes(user_types);
        setPermissions(perm);
        setModules(responseJson.modules);
        setResponseJson(responseJson);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  }, []);

  const save = (values) => {
    if (selectedUserType == "") {
      toast.error("Please select user type!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (selectedStatus == "") {
      toast.error("Please select status!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    let permission_array = new Array();
    selectedPermissions.map((element) => {
      permission_array.push({
        id: element,
      });
    });

    if (permission_array.length <= 0) {
      toast.error("Please select at least 1 permission!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    // console.log({
    //   name: values.role_name,
    //   role_type: {
    //     id: selectedUserType,
    //   },
    //   status_info: {
    //     id: selectedStatus,
    //   },
    //   permission: permission_array,
    // });

    // // console.log(values);
    // return;

    setLoading(true);

    fetch(AdminGlobal.ADDROLE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: values.role_name,
        role_type: {
          id: selectedUserType,
        },
        status_info: {
          id: selectedStatus,
        },
        permission: permission_array,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        setLoading(false);
        if (responseJson.status == 200) {
          toast.success("Saved!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            history.goBack();
          }, 1200);
        } else {
          toast.error(responseJson.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  const roleSchema = Yup.object({
    role_name: Yup.string()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
  });

  const errors = {
    textAlign: "right",
    fontSize: 14,
    color: "red",
    fontWeight: "bold",
    borderTopWidth: 0.8,
    borderColor: "red",
    borderRadius: 8,
  };

  function autoCapitalize(str) {
    str = str.replace("_", " ");
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div id="main" style={{ fontFamily: "Nunito" }}>
      <ToastContainer />
      <div className="row">
        <div
          style={{
            backgroundColor: "rgb(240, 240, 240,0.2)",
            color: colors.primary_blue,
          }}
          className="card-header card-header-rose card-header-text my-4"
        >
          <div className="card-text">
            <h4 className="card-title boldHeading">Add Role</h4>
          </div>
        </div>
        <div className="col-md-12">
          <Formik
            initialValues={{
              role_name: "",
              selected_user_type: "",
              selected_status: "",
              selected_permission: "",
            }}
            validationSchema={roleSchema}
            onSubmit={(values, { setSubmitting }) => {
              save(values);
            }}
          >
            {(props) => (
              <form
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    e.preventDefault();
                  }
                }}
                className="form-horizontal"
              >
                <div
                  className="card"
                  style={{ border: "solid 0px white", borderRadius: "10px" }}
                >
                  {responseJson.status == 200 ? (
                    <div className="card-body ">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Role Name <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("role_name")}
                              type="tel"
                              className="form-control"
                            />
                            {props.touched.role_name &&
                            props.errors.role_name ? (
                              <div style={errors}>
                                {props.touched.role_name &&
                                  props.errors.role_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Type <b style={{ color: "red" }}>*</b>
                            </label>
                            <Select
                              placeholder="Select Type"
                              onChange={(selected) => {
                                setSelectedUserType(selected.value);
                              }}
                              options={userTypes}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Status <b style={{ color: "red" }}>*</b>
                            </label>
                            <Select
                              placeholder="Select Status"
                              onChange={(selected) => {
                                setSelectedStatus(selected.value);
                              }}
                              options={status}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>

                <div
                  className="card"
                  style={{ border: "solid 0px white", borderRadius: "10px" }}
                >
                  {responseJson.status == 200 ? (
                    <div className="card-body ">
                      <div className="row col-sm-12">
                        <div
                          className="col-sm-4 col-form-label"
                          style={{
                            textAlign: "left",
                            fontWeight: "bolder",
                            fontSize: "2vw",
                            padding: "2%",
                            color: colors.primary_blue,
                          }}
                        >
                          All Permissions
                        </div>
                        <div
                          className="row col-sm-8"
                          style={{
                            border: "1px",
                            // borderStyle: "groove",
                          }}
                        >
                          <div
                            className="col-sm-3"
                            style={{ alignSelf: "center", marginLeft: -5 }}
                          >
                            <div className="form-group">
                              <span
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "1.5vw",
                                }}
                              >
                                {" "}
                              </span>
                            </div>
                          </div>
                          <div
                            className="col-sm-3"
                            style={{ alignSelf: "center", textAlign: "left" }}
                          >
                            <div className="form-group">
                              <span
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "1.5vw",
                                  color: colors.primary_blue,
                                }}
                              >
                                Create
                              </span>
                            </div>
                          </div>
                          <div
                            className="col-sm-3"
                            style={{ alignSelf: "center", textAlign: "left" }}
                          >
                            <div className="form-group">
                              <span
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "1.5vw",
                                  color: colors.primary_blue,
                                }}
                              >
                                Edit
                              </span>
                            </div>
                          </div>
                          <div
                            className="col-sm-3"
                            style={{ alignSelf: "center", marginLeft: -5 }}
                          >
                            <div className="form-group">
                              <span
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "1.5vw",
                                  color: colors.primary_blue,
                                }}
                              >
                                View
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {modules.map((object) => {
                        return (
                          <Fragment key={object.id.toString()}>
                            <div className="row col-sm-12">
                              <div
                                className="col-sm-4 col-form-label"
                                style={{
                                  textAlign: "left",
                                  fontSize: "1.2vw",
                                  padding: "1%",
                                  // borderWidth: "1px",
                                  // borderStyle: "groove",
                                }}
                              >
                                {object.name}
                              </div>
                              <div
                                className="row col-sm-8"
                                style={
                                  {
                                    // border: "0.1px",
                                    // borderStyle: "groove",
                                  }
                                }
                              >
                                <div
                                  className="col-sm-3"
                                  style={{ alignSelf: "center" }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* <input 
                                    type="checkbox"
                                    onClick={(e)=>{
                                      object.permissions.map((val)=>{
                                        const checked =
                                                  e.target.checked;
                                                if (checked === true) {
                                                  selectedPermissions.push(
                                                    val.id.toString()
                                                  );
                                                } else {
                                                  var a =
                                                    selectedPermissions.indexOf(
                                                      val.id.toString()
                                                    );
                                                  selectedPermissions.splice(
                                                    a,
                                                    1
                                                  );
                                                }
                                      })
                                      
                                    }} 
                                    /> */}
                                  </div>
                                </div>
                                {object.permissions
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map((element) => {
                                    return (
                                      <Fragment key={element.id.toString()}>
                                        <div
                                          className="col-sm-3"
                                          style={{ alignSelf: "center" }}
                                        >
                                          <div
                                            style={{
                                              alignItems: "center",
                                              justifyContent: "center",
                                              alignSelf: "center",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              value={element.id}
                                              // checked={()=>
                                              //   selectedPermissions.indexOf(
                                              //     element.id
                                              //   )== -1 ? true : false}
                                              onClick={(e) => {
                                                const checked =
                                                  e.target.checked;
                                                if (checked === true) {
                                                  selectedPermissions.push(
                                                    e.target.value
                                                  );
                                                } else {
                                                  var a =
                                                    selectedPermissions.indexOf(
                                                      e.target.value
                                                    );
                                                  selectedPermissions.splice(
                                                    a,
                                                    1
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                          </Fragment>
                        );
                        // }
                      })}
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                          }}
                          className="btn btn-gh rounded-pill"
                          onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                          }}
                        >
                          Back
                        </button>
                        {loading === false ? (
                          <button
                            style={{
                              backgroundColor: colors.primary_blue,
                              color: "white",
                              marginLeft: 5,
                            }}
                            type="submit"
                            className="btn btn-fill btn-gh rounded-pill"
                            onClick={props.handleSubmit}
                          >
                            Submit
                          </button>
                        ) : (
                          <Loaderr
                            type="Oval"
                            color="#245b7a"
                            height={58}
                            width={58}
                            // timeout={3000} //3 secs
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddRole;
