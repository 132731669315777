import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import DataTable, { defaultThemes } from "react-data-table-component";
import { colors } from "../../Helpers/ColorsPalette";
import Loader from "../../Loader/Loader";
import { Button, Form } from "react-bootstrap";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import { TextArea } from "semantic-ui-react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaGetPocket } from "react-icons/fa";
import moment from "moment-timezone";

const ViewComplaint = () => {
  let history = useHistory();
  let location = useLocation();

  const [token, setToken] = useState(null);
  const [loading, setloading] = useState(false);
  const [responseJson, setResponseJson] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
    setloading(true);

    fetch(AdminGlobal.GETCOMPLAINTBYID + location.state?.id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        setResponseJson(responseJson.data);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  }, []);

  return (
    <div id="main" style={{ fontFamily: "Nunito" }}>
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <div className="row">
        <div
          style={{
            backgroundColor: "rgb(240, 240, 240,0.2)",
            color: colors.primary_blue,
          }}
          className="card-header card-header-rose card-header-text my-4"
        >
          <div className="card-text">
            <h4 className="card-title boldHeading">
              View Complaint (Complaint ID: {location.state?.id})
            </h4>
          </div>
        </div>
        <div className="col-md-12">
          <div
            className="card"
            style={{ border: "solid 0px white", borderRadius: "10px" }}
          >
            {responseJson != null ? (
              <div className="card-body ">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="basicInput">Complaint Type</label>
                      <input
                        disabled
                        defaultValue={responseJson?.complaint_type?.name}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="basicInput">Category</label>
                      <input
                        disabled
                        defaultValue={responseJson?.category?.category_name}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="basicInput">Subject</label>
                      <input
                        disabled
                        defaultValue={responseJson?.subject}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="basicInput">Details</label>
                      <TextArea
                        disabled
                        defaultValue={responseJson?.details}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="basicInput">Branch</label>
                      <input
                        disabled
                        defaultValue={responseJson?.fuel_station?.name}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="basicInput">Priority</label>
                      <input
                        disabled
                        defaultValue={responseJson?.priority}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="basicInput">Initiated By</label>
                      <input
                        disabled
                        defaultValue={
                          responseJson?.complainant_user?.first_name +
                          " " +
                          responseJson?.complainant_user?.last_name
                        }
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="basicInput">Related To</label>
                      <input
                        disabled
                        defaultValue={responseJson?.department_type?.name}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="basicInput">Pay Via</label>
                      <input
                        disabled
                        defaultValue={responseJson?.transfer_via}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="basicInput">Complaint Status</label>
                      <input
                        disabled
                        defaultValue={responseJson?.complaint_status?.name}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                {responseJson?.complaint_type_id == 1
                  ? responseJson?.issues.map((item, index) => {
                      return (
                        <div
                          className="row"
                          style={{ padding: "0 1% 0 1%" }}
                          key={item?.id.toString()}
                        >
                          <div
                            className="card"
                            style={{
                              borderRadius: "1%",
                            }}
                          >
                            <div className="card-body">
                              <div className="card-title">
                                <h5>
                                  <b>Issue image {index + 1}</b>
                                </h5>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <img
                                  src={
                                    "http://backend.quickpower.sa/public/complaint_issues/" +
                                    item?.name
                                  }
                                  style={{
                                    width: "100%",
                                    maxWidth: "20rem",
                                    height: "auto",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : responseJson?.complaint_type_id == 2 ||
                    responseJson?.complaint_type_id == 3
                  ? responseJson?.quotations.map((item, index) => {
                      return (
                        <>
                          <div
                            className="row"
                            style={{ padding: "0 1% 0 1%" }}
                            key={item?.id.toString()}
                          >
                            <div
                              className="card"
                              style={{
                                borderRadius: "1%",
                                border:
                                  item?.id ==
                                  responseJson?.selected_quotation_id
                                    ? "3px solid green"
                                    : {},
                              }}
                            >
                              <div className="card-body">
                                <div className="card-title">
                                  <h5>
                                    <b>Quotation {index + 1}</b>
                                    {item?.id ==
                                    responseJson?.selected_quotation_id ? (
                                      <i
                                        style={{
                                          color: "green",
                                          fontSize: "2rem",
                                        }}
                                        className="bi bi-check-lg"
                                      />
                                    ) : null}
                                  </h5>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <h4>
                                    <b>Company Name: </b>
                                    {item?.company_name}
                                  </h4>
                                  <h4>
                                    <b>Estimate: </b>
                                    {item?.estimate} SAR
                                  </h4>
                                  {item?.file_type == "application/pdf" ? (
                                    <>
                                      <span
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            "http://backend.quickpower.sa/public/storage/uploads/" +
                                              item?.name
                                          );
                                          toast.success("Copied to clipboard", {
                                            position: toast.POSITION.TOP_CENTER,
                                          });
                                        }}
                                      >
                                        <i
                                          className="bi bi-filetype-pdf"
                                          style={{
                                            fontSize: "1rem",
                                          }}
                                        ></i>
                                        <span
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Click here to copy link
                                        </span>
                                      </span>
                                      <a
                                        href={
                                          "http://backend.quickpower.sa/public/storage/uploads/" +
                                          item?.name
                                        }
                                        target="_blank"
                                      >
                                        <Button>
                                          <i
                                            className="bi bi-filetype-pdf"
                                            style={{
                                              fontSize: "1rem",
                                            }}
                                          ></i>
                                          <span
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            Download
                                          </span>
                                        </Button>
                                      </a>
                                    </>
                                  ) : (
                                    <img
                                      src={
                                        "http://backend.quickpower.sa/public/complaint_quotations/" +
                                        item?.name
                                      }
                                      style={{
                                        width: "100%",
                                        maxWidth: "20rem",
                                        height: "auto",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : null}

                <div className="row">
                  {responseJson?.quotation_site_images.map((item, index) => {
                    return (
                      <div
                        className="col-md-4"
                        style={{ padding: "0 1% 0 1%" }}
                        key={item?.id.toString()}
                      >
                        <div
                          className="card"
                          style={{
                            borderRadius: "1%",
                            border:
                              item?.id == responseJson?.selected_quotation_id
                                ? "3px solid green"
                                : {},
                          }}
                        >
                          <div className="card-body">
                            <div className="card-title">
                              <h5>
                                <b>Additional Image {index + 1}</b>
                              </h5>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <img
                                src={
                                  "http://backend.quickpower.sa/public/complaint_quotation_site_images/" +
                                  item?.name
                                }
                                style={{
                                  width: "100%",
                                  maxWidth: "20rem",
                                  height: "auto",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {responseJson?.complaint_logs.length > 0
                  ? responseJson?.complaint_logs.map((item) => {
                      return (
                        <div style={{ backgroundColor: "#E3E3E3" }}>
                          <VerticalTimeline
                            layout="1-column-left"
                            lineColor="black"
                          >
                            <VerticalTimelineElement
                              className="vertical-timeline-element"
                              contentStyle={{
                                color: "#000",
                              }}
                              contentArrowStyle={{
                                borderRight: "7px solid  #235692",
                              }}
                              date={
                                <Moment format="DD/MM/YYYY">
                                  {item?.created_at}
                                </Moment>
                              }
                              iconStyle={{
                                background: "#235692",
                                color: "#fff",
                              }}
                              icon={<FaGetPocket />}
                            >
                              <h3 className="vertical-timeline-element-title">
                                {item?.action_performed}
                              </h3>
                              {item?.notes != null ? (
                                <h4 className="vertical-timeline-element-subtitle">
                                  <i>Notes: {" " + item?.notes}</i>
                                </h4>
                              ) : null}
                              <p>
                                <i>
                                  Action performed by:{" "}
                                  {item?.action_performed_by_user?.first_name +
                                    " "}
                                  {item?.action_performed_by_user?.last_name}
                                </i>
                              </p>
                            </VerticalTimelineElement>
                          </VerticalTimeline>
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewComplaint;
