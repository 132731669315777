import React, { Component, useState, useEffect } from "react";
import { FastField, Formik } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../Loader/Loader";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import * as Yup from "yup";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { colors } from "../../Helpers/ColorsPalette";
import { Helmet } from "react-helmet";
const UserProfile = () => {
  let history = useHistory();
  let location = useLocation();
  const [token, setToken] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [selectedEmployeeJobStatus, setSelectedEmployeeJobStatus] =
    useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [user, setUser] = useState("");

  const [currentRole, setCurrentRole] = useState("");
  const [currentEmployeeName, setCurrentEmployeeName] = useState("");
  const [currentUserName, setCurrentUserName] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentMobileNumber, setCurrentMobileNumber] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [responseJson, setResponseJson] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [c_password, set_c_password] = useState(null);
  const [values, setValues] = useState("");
  const [subAdminId, setSubAdminId] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [currentDesignation, setCurrentDesignation] = useState(null);

  const errorStyle = {
    textAlign: "right",
    fontSize: 14,
    color: "red",
    fontWeight: "bold",
    borderTopWidth: 0.8,
    borderColor: "red",
    borderRadius: 8,
  };

  const adminSchema = Yup.object({
    employee_name: Yup.string()
      .min(3, "Too Short!")
      .nullable()
      .max(30, "Too Long!")
      .required("Required"),

    mobile_number: Yup.string()
      .nullable()
      .matches(
        "^((\\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$",
        "Phone number is not valid"
      )
      .max(11, "Invalid Number")
      .required("Required!"),

    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .nullable(),
    confirm_password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .nullable(),
  });

  useEffect(() => {
    const user_object = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    setLoading(true);
    fetch(AdminGlobal.GETUSERPROFILE, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setResponseJson(responseJson);
        setCurrentRole(responseJson.data.roles[0]);
        setSelectedRole(responseJson.data.roles[0].id);
        setCurrentEmployeeName(responseJson.data.name);
        setEmployeeName(responseJson.data.name);

        setCurrentUserName(responseJson.data.user_name);
        setUserName(responseJson.data.user_name);
        setCurrentEmail(responseJson.data.email);
        if (user_object.user_type.id == 2) {
          setCurrentDesignation(responseJson.data.system_admin.designation);
          setSelectedDesignation(responseJson.data.system_admin.designation_id);
          setCurrentMobileNumber(responseJson.data.system_admin.contact_number);
          setMobileNumber(responseJson.data.system_admin.contact_number);
          setCurrentStatus(responseJson.data.system_admin.status_info);
          setSelectedStatus(responseJson.data.system_admin.status_info.id);
          setSubAdminId(responseJson.data.system_admin.id);
        } else if (user_object.user_type.id == 3) {
          setCurrentDesignation(
            responseJson.data.organizational_admin.designation
          );
          setSelectedDesignation(
            responseJson.data.organizational_admin.designation_id
          );
          setCurrentMobileNumber(
            responseJson.data.organizational_admin.contact_number
          );
          setMobileNumber(
            responseJson.data.organizational_admin.contact_number
          );
          setCurrentStatus(responseJson.data.organizational_admin.status_info);
          setSelectedStatus(
            responseJson.data.organizational_admin.status_info.id
          );
          setSubAdminId(responseJson.data.organizational_admin.id);
        } else if (user_object.user_type.id == 4) {
          setCurrentDesignation(
            responseJson.data.organizational_user.designation
          );
          setSelectedDesignation(
            responseJson.data.organizational_user.designation_id
          );
          setCurrentMobileNumber(
            responseJson.data.organizational_user.contact_number
          );
          setMobileNumber(responseJson.data.organizational_user.contact_number);
          setCurrentStatus(responseJson.data.organizational_user.status_info);
          setSelectedStatus(
            responseJson.data.organizational_user.status_info.id
          );
          setSubAdminId(responseJson.data.organizational_user.id);
        }
        setLoaded(true);
        setLoading(false);
      })
      .catch((error) => {
        //Hide Loader
        setLoading(false);
        console.error(error);
      });
  }, []);

  const save = () => {
    if (selectedDesignation == null) {
      toast.error("Please select your Designation!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const token = localStorage.getItem("token");
    fetch(AdminGlobal.GETUSERPROFILE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        role: {
          id: selectedRole,
        },
        name: values.employee_name,
        designation: { id: selectedDesignation },
        user_name: userName,
        password: values.password,
        email: null,
        contact_number: values.mobile_number,
        status_info: {
          id: selectedStatus,
        },
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          toast.success("Profile Updated Successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          history.push("/dashboard");
        } else {
          toast.error(responseJson.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  const verifyPassword = () => {
    const token = localStorage.getItem("token");
    if (c_password == null) {
      toast.error("Password Required!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    fetch(AdminGlobal.CHECKPASSWORD, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: c_password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          setIsOpen(false);
          toast.success("Success!", {
            position: toast.POSITION.TOP_CENTER,
          });
          save();
        } else {
          toast.error(responseJson.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div id="main" style={{ fontFamily: "Nunito" }}>
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <div className="row">
        <div
          style={{
            backgroundColor: "rgb(240, 240, 240,0.2)",
            color: colors.primary_blue,
          }}
          className="card-header card-header-rose card-header-text my-4"
        >
          <div className="card-icon">
            {/* <i class="bi bi-person-lines-fill"></i> */}
          </div>
          <h4 className="card-title boldHeading">User Profile</h4>
        </div>
        <div className="col-md-12">
          <div
            className="card"
            style={{ border: "solid 0px white", borderRadius: "10px" }}
          >
            {loading == false ? (
              <div className="card-body ">
                <Formik
                  initialValues={{
                    employee_name: currentEmployeeName,
                    mobile_number: currentMobileNumber,
                    password: "",
                    confirm_password: "",
                  }}
                  validationSchema={adminSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (values.password != values.confirm_password) {
                      toast.error("Passwords do not match!", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                      return;
                    }
                    // setSubmitting(true);
                    // save(values);
                    setValues(values);
                    save();
                  }}
                >
                  {(props) => (
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      className="form-horizontal"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Role <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={(e) => {}}
                              type="tel"
                              className="form-control"
                              defaultValue={currentRole?.name}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Employee Name <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("employee_name")}
                              type="tel"
                              disabled
                              className="form-control"
                              defaultValue={currentEmployeeName}
                            />
                            {props.touched.employee_name &&
                            props.errors.employee_name ? (
                              <div style={errorStyle}>
                                {props.touched.employee_name &&
                                  props.errors.employee_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {currentDesignation != null ? (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                Designation <b style={{ color: "red" }}>*</b>
                              </label>
                              <Select
                                isDisabled={true}
                                onChange={(selected) => {
                                  setSelectedDesignation(selected.value);
                                  // values.selected_role = selected.value;
                                }}
                                options={designations}
                                defaultValue={{
                                  label: currentDesignation.name,
                                  value: currentDesignation.id,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              User Name <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={(e) => setUserName(e.target.value)}
                              type="tel"
                              disabled
                              className="form-control"
                              defaultValue={currentUserName}
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div className="row">
                          <div className="col-sm-2 col-form-label">
                            Password
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <input
                                onChange={props.handleChange("password")}
                                type="password"
                                className="form-control"
                                disabled={!passwordToggle}
                                placeholder="********"
                              />
                            </div>
                            {props.touched.password && props.errors.password ? (
                              <div style={errorStyle}>
                                {props.touched.password &&
                                  props.errors.password}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-sm-2 col-form-label">
                            Confirm Password
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <input
                                onChange={props.handleChange(
                                  "confirm_password"
                                )}
                                type="password"
                                className="form-control"
                                disabled={!passwordToggle}
                                placeholder="********"
                              />
                            </div>
                            {props.touched.confirm_password &&
                            props.errors.confirm_password ? (
                              <div style={errorStyle}>
                                {props.touched.confirm_password &&
                                  props.errors.confirm_password}
                              </div>
                            ) : null}
                            {passwordToggle === false ? (
                              <button
                                style={{
                                  padding: 8,
                                  color: "white",
                                }}
                                onClick={() => {
                                  setPasswordToggle(true);
                                }}
                                type="button"
                                className="btn btn-gh"
                              >
                                Change Password
                              </button>
                            ) : null}
                          </div>
                        </div> */}

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Email <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={(e) => {}}
                              type="email"
                              className="form-control"
                              disabled
                              defaultValue={currentEmail}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Mobile Number <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("mobile_number")}
                              type="tel"
                              disabled
                              className="form-control"
                              defaultValue={currentMobileNumber}
                            />
                            {props.touched.mobile_number &&
                            props.errors.mobile_number ? (
                              <div style={errorStyle}>
                                {props.touched.mobile_number &&
                                  props.errors.mobile_number}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                          }}
                          className="btn btn-gh rounded-pill"
                          onClick={(e) => {
                            e.preventDefault()
                            history.goBack()}}
                        >
                          Back
                        </button>
                        {/* <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                            marginLeft: 5,
                          }}
                          type="submit"
                          onClick={props.handleSubmit}
                          className="btn btn-fill btn-gh"
                        >
                          Update
                        </button> */}
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
      <Modal
        showCloseIcon={false}
        center
        styles={{
          modal: { overflow: "hidden", borderRadius: 10 },
        }}
        onClose={() => setIsOpen(false)}
        open={modalIsOpen}
      >
        <div
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="card-body" style={{ padding: "10%" }}>
            <div>
              <p style={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                Verify Password
              </p>
            </div>
            <span className="bmd-form-group">
              <div className="input-group">
                <input
                  type={"password"}
                  className="form-control"
                  placeholder="current password..."
                  onChange={(e) => set_c_password(e.target.value)}
                  style={{
                    border: "1px solid black",
                    padding: 10,
                    marginBottom: -10,
                  }}
                />
              </div>
            </span>
          </div>
          <div className="card-footer">
            <button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(false);
              }}
              className="btn"
              style={{
                backgroundColor: "white",
                color: "black",
                borderColor: "gray",
                marginRight: 5,
              }}
              // style={{backgroundColor:'white',fontSize:12, borderRadius:2, borderWidth:1, borderColor:'gray', marginRight:3, paddingTop:7, paddingBottom:7,paddingRight:20, paddingLeft:20, marginTop:3, }}
            >
              CANCEL
            </button>
            <button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                // verifyPassword();
              }}
              className="btn btn-gh"
            >
              Verify
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UserProfile;
