import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Loader from "../../Loader/Loader";
import * as Yup from "yup";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { colors } from "../../Helpers/ColorsPalette";
import ProgressLoader from "react-loader-spinner";
import { Helmet } from "react-helmet";

const AddCategory = () => {
  let history = useHistory();
  const [token, setToken] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [loading, setloading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const [allStatuses, setAllStatuses] = useState([
    {
      value:1,
      label:"Active"
    },
    {
      value:2,
      label:"Inactive"
    }
  ])

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken("Bearer " + token);

  }, []);

  const errors = {
    textAlign: "right",
    fontSize: 14,
    color: "red",
    fontWeight: "bold",
    borderTopWidth: 0.8,
    borderColor: "red",
    borderRadius: 8,
  };

  const addNewCategory = (values) => {
    setSubmitLoader(true);
    fetch(AdminGlobal.ADDCATEGORY, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        "Content-type":'application/json'
      },
      body: JSON.stringify({
        category_name:values.category_name,
        category_description:values.category_description,
        status_info:{id:selectedStatus.value}
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          toast.success("Saved!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            history.push("/categories");
          }, 2000);
        } else {
          toast.error("There seems to be an error!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setSubmitLoader(false);
      })
      .catch((error) => {
        //Hide Loader
        setSubmitLoader(false);
        console.error(error);
      });
  };

  return (
    <div id="main" style={{ fontFamily: "Nunito" }}>
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <div className="row">
        <div
          style={{
            backgroundColor: "rgb(240, 240, 240,0.2)",
            color: colors.primary_blue,
          }}
          className="card-header card-header-rose card-header-text my-4"
        >
          <div className="card-text">
            <h4 className="card-title boldHeading">Add Category</h4>
          </div>
        </div>
        <div className="col-md-12">
          <div
            className="card"
            style={{ border: "solid 0px white", borderRadius: "10px" }}
          >
            {loading == false ? (
              <div className="card-body ">
                <Formik
                  initialValues={{
                    category_name:"",
                    category_description:"",
                  }}
                  onSubmit={(values) => {
                    addNewCategory(values)
                  }}
                >
                  {(props) => (
                    <form
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          e.preventDefault();
                        }
                      }}
                      className="form-horizontal"
                    >
                      

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Category Name <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange('category_name')}
                              type="tel"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Category Description <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange('category_description')}
                              type="tel"
                              className="form-control"
                              required
                            />
                          
                          </div>
                        </div>
                      </div>
                      <div className="row">
                       
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Status <b style={{ color: "red" }}>*</b>
                            </label>
                            <Select
                              placeholder="Select Status"
                              onChange={(selected) => {
                                setSelectedStatus(selected);
                              }}
                              options={allStatuses}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          
                          </div>
                        </div>
                      </div>


                     

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                          }}
                          className="btn btn-gh rounded-pill"
                          onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                          }}
                        >
                          Back
                        </button>
                        <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                            marginLeft: 5,
                          }}
                          type="submit"
                          className="btn btn-fill btn-gh rounded-pill"
                          // onClick={props.handleSubmit}
                          onClick={(e)=>{
                            e.preventDefault()
                            props.handleSubmit()
                          }}
                        >
                          {submitLoader == true ? (
                            <ProgressLoader
                              color={"white"}
                              height={50}
                              width={50}
                              type={"Oval"}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
