import React, { Component } from "react";
import Lottie from "lottie-react";
import loadersrc from "./qloader.json";

export default class Loader extends Component {
  render() {
    return (
      <div style={{ textAlign: "-webkit-center" , display:'flex', alignItems:'center', justifyContent:'center'}}>
        <Lottie
          animationData={loadersrc}
          loop={true}
          autoplay={true}
          style={{ width: "20%",height:"auto" }}
        />
      </div>
    );
  }
}
