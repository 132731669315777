import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../Loader/Loader";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { colors } from "../../Helpers/ColorsPalette";
import ProgressLoader from "react-loader-spinner";
import { Helmet } from "react-helmet";

const ViewFuelStation = () => {
    let location = useLocation()
  let history = useHistory();
  const [token, setToken] = useState(null);
  const [allStatuses, setAllStatuses] = useState([])
  const [selectedStatus, setSelectedStatus] = useState("");
  const [responseJson, setResponseJson] = useState(null);
  const [passwordScore, setPasswordScore] = useState(0);
  const [loading, setloading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null)
  const [submitLoader, setSubmitLoader] = useState(false);
  const [selectedstatus, setselectedStatus] = useState(null)
  const [allFuelStationManagers, setAllFuelStationManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null)


  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken("Bearer " + token);

    fetch(AdminGlobal.GETFUELSTATIONBYID+location.state.id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
         if(responseJson.status==200){
           setResponseJson(responseJson.data)
           setSelectedStatus({
             value:responseJson.data.status_info.id,
             label:responseJson.data.status_info.name
           })
         }
         setloading(false)
        })
        .catch((error) => {
          //Hide Loader
          console.error(error);
        });

        fetch(AdminGlobal.GETALLSTATUSES, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            const options = responseJson.data.data.map((d) => ({
              value: d.id,
              label: d.name,
            }));
            setAllStatuses(options);
          })
          .catch((error) => {
            //Hide Loader
            console.error(error);
          });

          fetch(AdminGlobal.GETALLFUELSTATIONMANAGETERS, {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          })
            .then((response) => response.json())
            .then((responseJson) => {
              if(responseJson.status == 200) {
                const options = responseJson.data.map((d) => ({
                  value: d.id,
                  label: d?.first_name+d?.last_name,
                }));
                setAllFuelStationManagers(options);
              }
              setloading(false)
            })
            .catch((error) => {
              console.error(error);
              setloading(false)
            });
    

  }, []);

  const subAdminSchema = Yup.object({
    employee_name: Yup.string()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    user_name: Yup.string()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid Email").required("Required"),
    mobile_number: Yup.string()
      .matches(
        "^((\\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$",
        "Phone number is not valid"
      )
      .max(11, "Invalid Number")
      .required("Required!"),
  });

  const errors = {
    textAlign: "right",
    fontSize: 14,
    color: "red",
    fontWeight: "bold",
    borderTopWidth: 0.8,
    borderColor: "red",
    borderRadius: 8,
  };

  const saveFuelStation = (values) => {
    setSubmitLoader(true);
    fetch(AdminGlobal.EDITFUELSTATION+location.state.id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        'Content-Type':'application/json'
      },
      body: JSON.stringify({
        name:values.name,
        location:values.location,
        status_info:{id:selectedStatus.value},
        fuel_station_manager:{id:selectedManager.value},
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          toast.success("Saved!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            history.push("/fuelstations");
          }, 2000);
        } else {
          toast.error("There seems to be an error!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setSubmitLoader(false);
      })
      .catch((error) => {
        //Hide Loader
        setSubmitLoader(false);
        console.error(error);
      });
  };

  return (
    <div id="main" style={{ fontFamily: "Nunito" }}>
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <div className="row">
        <div
          style={{
            backgroundColor: "rgb(240, 240, 240,0.2)",
            color: colors.primary_blue,
          }}
          className="card-header card-header-rose card-header-text my-4"
        >
          <div className="card-text">
            <h4 className="card-title boldHeading">View Fuel Station</h4>
          </div>
        </div>
        <div className="col-md-12">
          <div
            className="card"
            style={{ border: "solid 0px white", borderRadius: "10px" }}
          >
            {responseJson !=null ? (
              <div className="card-body ">
                <Formik
                initialValues={{
                  name:responseJson.name,
                  location:responseJson.location
                }}
                  onSubmit={(values) => {
                    saveFuelStation(values)
                  }}
                >
                  {(props) => (
                    <form
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          e.preventDefault();
                        }
                      }}
                      className="form-horizontal"
                    >
                      

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Station Name <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange('name')}
                              type="tel"
                              disabled
                              defaultValue={props.values.name}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Station Location <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange('location')}
                              type="tel"
                              disabled
                              defaultValue={props.values.location}
                              className="form-control"
                              required
                            />
                          
                          </div>
                        </div>
                      </div>

                      {
                        selectedStatus!=null?
                        <div className="row">
                          <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Assign Manager <b style={{ color: "red" }}>*</b>
                            </label>
                            <Select
                               placeholder="Select Manager"
                               onChange={(selected) => {
                                 setSelectedManager(selected);
                               }}
                               isDisabled
                               options={allFuelStationManagers}
                               menuPortalTarget={document.body}
                               styles={{
                                 menuPortal: (base) => ({
                                   ...base,
                                   zIndex: 9999,
                                 }),
                               }}
                             />
                          </div>
                        </div>
                         <div className="col-md-6">
                           <div className="form-group">
                             <label htmlFor="basicInput">
                               Status <b style={{ color: "red" }}>*</b>
                             </label>
                             <Select
                               placeholder="Select Status"
                               onChange={(selected) => {
                                 setSelectedStatus(selected);
                               }}
                               isDisabled
                               defaultValue={selectedStatus}
                               options={allStatuses}
                               menuPortalTarget={document.body}
                               styles={{
                                 menuPortal: (base) => ({
                                   ...base,
                                   zIndex: 9999,
                                 }),
                               }}
                             />
                           </div>
                         </div>
                       </div>
                       : null
                      }

                     

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                          }}
                          className="btn btn-gh rounded-pill"
                          onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewFuelStation;
