import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { colors } from "../../Helpers/ColorsPalette";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Form } from "react-bootstrap";
import { Helmet } from "react-helmet";

const AllRoles = () => {
  let history = useHistory();
  const [roles, setRoles] = useState([]);
  const [token, setToken] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [allowed, setAllowed] = useState(true);
  const [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  let count = 1;
  const cuser = localStorage.getItem("user");
  let currentUser = JSON.parse(cuser);
  const customStyles = {
    headRow: {
      style: {
        border: "none",
        borderRadius: 10,
        paddingTop: 20,
        paddingBottom: 30,
      },
    },
    table: {
      style: {
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    responsiveWrapper: {
      style: {
        backgroundColor: "#f1f1f1",
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    tableWrapper: {
      style: {
        backgroundColor: "#f1f1f1",
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    cells: {
      style: {
        display: "flex",
        "flex-wrap": "wrap",
        "justify-content": "center",
        border: "1px solid white",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
        display: "flex",
        "flex-wrap": "wrap",
        "justify-content": "center",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        ackgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        marginTop: 2,
        border: "2px solid white",
        "border-bottom-left-radius": "25px",
        "border-bottom-right-radius": "25px",
      },
    },
  };

  useEffect(() => {
    console.log("roles");
    const token = localStorage.getItem("token");
    setToken(token);
    setLoading(true);
    // fetch(AdminGlobal.STATUS, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: "Bearer " + token,
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     const options = responseJson.data.data.map((d) => ({
    //       value: d.id,
    //       label: d.name,
    //     }));
    //     setStatusOptions(options);
    //   })
    //   .catch((error) => {
    //     //Hide Loader
    //     console.error(error);
    //   });

    fetch(AdminGlobal.GETALLROLES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 405) {
          setAllowed(false);
          setRoles([]);
          setEmpty(true);
        } else if (responseJson.status == 200) {
          setRoles(responseJson.roles);
          setAllowed(true);
          setEmpty(false);
        } else if (responseJson.status == 201) {
          setRoles([]);
          setEmpty(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, []);

  const columns = [
    {
      name: "SrNo",
      sortable: true,
      selector: (row, index) => index + 1,
    },
    {
      name: "Name",
      sortable: true,
      sortable: true,
      selector: (row) => row?.name,
    },
    {
      name: "Type",
      sortable: true,
      sortable: true,
      selector: (row) => row?.user_type,
    },
    // {
    //   name:
    //     currentUser.granted_permissions_array.indexOf("role_edit") > -1 === true
    //       ? "Verified"
    //       : null,
    //   // selector: row => row.seller_info.business_name,
    //   cell: (row) => (
    //     <div>
    //       {currentUser.granted_permissions_array.indexOf("role_edit") > -1 ===
    //       true ? (
    //         row.status_info ? (
    //           <Form>
    //             <Form.Check
    //               defaultChecked={row.status_info.id == 1 ? true : false}
    //               onChange={(e) => {
    //                 if (e.target.checked == true) {
    //                   updateStatus(row.id, 1);
    //                 } else {
    //                   updateStatus(row.id, 2);
    //                 }
    //               }}
    //               style={{ display: "flex", alignItems: "center" }}
    //               type="switch"
    //               id="custom-switch"
    //               label="Active"
    //             />
    //           </Form>
    //         ) : null
    //       ) : null}
    //     </div>
    //   ),
    // },
    {
      name: "Action",
      // selector: row => row.seller_info.business_name,
      cell: (row) => (
        <div>
          {currentUser.granted_permissions_array.indexOf("role_view") > -1 ===
          true ? (
            <button
              style={{
                backgroundColor: colors.primary_blue,
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() =>
                history.push({
                  pathname: "/roles/view",
                  state: {
                    id: row.id,
                  },
                })
              }
              type="button"
              rel="tooltip"
              className="btn btn-primary"
            >
              <i className="bi bi-eye-fill"></i>
            </button>
          ) : null}
          {currentUser.granted_permissions_array.indexOf("role_edit") > -1 ===
          true ? (
            <button
              style={{
                backgroundColor: colors.primary_blue,
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 5,
              }}
              onClick={() =>
                history.push({
                  pathname: "/roles/edit",
                  state: {
                    id: row.id,
                  },
                })
              }
              type="button"
              rel="tooltip"
              className="btn btn-primary"
            >
              <i className="bi bi-pencil-square"></i>
            </button>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: colors.primary_background }} id="main">
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <header className="mb-3">
        <a href="#" className="burger-btn d-block d-xl-none">
          <i className="bi bi-justify fs-3" />
        </a>
      </header>
      <div className="page-heading">
        <div className="page-title">
          <div className="row">
            <div className="col-12 col-md-6 order-md-1 order-last">
              <h3>Roles List</h3>
              <p className="text-subtitle text-muted"></p>
              <p className="text-subtitle text-muted"></p>
              <p className="text-subtitle text-muted"></p>
            </div>
            <div className="col-12 col-md-6 order-md-2 order-first">
              <nav
                aria-label="breadcrumb"
                className="breadcrumb-header float-start float-lg-end"
              >
                <button
                  style={{
                    marginLeft: 5,
                    backgroundColor: colors.primary_blue,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/roles/add");
                  }}
                  type="button"
                  rel="tooltip"
                  className="btn btn-primary"
                >
                  Add
                  <i style={{ marginLeft: 5 }} className="bi bi-plus-lg"></i>
                </button>
              </nav>
            </div>
          </div>
        </div>
        <DataTable
          pagination
          paginationRowsPerPageOptions={[10, 30, 50, 70, 100]}
          paginationPerPage={100}
          columns={columns}
          data={roles}
          progressPending={loading}
          progressComponent={<Loader />}
          striped
          dense
          customStyles={customStyles}
        />
      </div>
      <footer></footer>
    </div>
  );
};

export default AllRoles;
