import React, { useState, useEffect } from "react";
import { colors } from "../../Helpers/ColorsPalette";
import DataTable, { defaultThemes } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import Loader from "../../Loader/Loader";
import ShowParticipantsModal from "../../Helpers/showParticipantsModal";
import { Modal } from "react-responsive-modal";
import "../../Helpers/customModalcss.css";
import { Helmet } from "react-helmet";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import FileSaver, { saveAs } from "file-saver";
function FSExpenseReport() {
  let history = useHistory();
  const [user, setUser] = useState("");
  const userString = localStorage.getItem("user");
  const userObject = JSON.parse(userString);
  const [allFuelStations, setAllFuelStations] = useState([]);
  const [filteredFuelStations, setFilteredFuelStations] = useState([]);
  const [token, setToken] = useState(null);
  const [loading, setloading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const cuser = localStorage.getItem("user");
  let currentUser = JSON.parse(cuser);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  useEffect(() => {
    setUser(userObject);
    console.log({ userObject });
  }, []);
  useEffect(() => {
    setUser(userObject);
    const token = localStorage.getItem("token");
    setToken("Bearer " + token);
    setloading(true);

    fetch(AdminGlobal.GETFUELSTATIIONEXPENSEREPORT, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          let array = [];
          responseJson.data.map((item) => {
            item?.expenses.map((dt) => {
              let object = {
                srno: array.length + 1,
                fuel_station: item.name,
                expenses: dt,
                location: item.location,
              };
              array.push(object);
            });
          });
          console.log(array);
          setAllFuelStations(array);
          setFilteredFuelStations(array);
        }
        setloading(false);
      })
      .catch((error) => {
        //Hide Loader
        setloading(false);
        console.error(error);
      });
  }, []);
  const customStyles = {
    headRow: {
      style: {
        border: "none",
        borderRadius: 10,
        paddingTop: 20,
        paddingBottom: 30,
      },
    },
    table: {
      style: {
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    responsiveWrapper: {
      style: {
        backgroundColor: "#f1f1f1",
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    tableWrapper: {
      style: {
        backgroundColor: "#f1f1f1",
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    cells: {
      style: {
        display: "flex",
        "flex-wrap": "wrap",
        "justify-content": "center",
        border: "1px solid white",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
        display: "flex",
        "flex-wrap": "wrap",
        "justify-content": "center",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        ackgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        marginTop: 2,
        border: "2px solid white",
        "border-bottom-left-radius": "25px",
        "border-bottom-right-radius": "25px",
      },
    },
  };
  const columns = [
    {
      name: "Sr. No",
      width: "120px",
      sortable: true,
      selector: (row, index) => row.srno,
    },
    {
      name: "Fuel Station",
      sortable: true,
      selector: (row) => row?.fuel_station,
    },
    {
      name: "Location",
      sortable: true,
      selector: (row) => row?.location,
    },
    {
      name: "Company Name",
      sortable: true,
      selector: (row) => row?.expenses?.company_name,
    },

    {
      name: "Expense Estimate",
      sortable: true,
      selector: (row) => row?.expenses?.estimate,
    },
  ];

  const exportPDF = (arrayData) => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Fuel Stations Expense Report";
    const headers = [
      [
        "Sr. No",
        "Fuel Station",
        "Location",
        "Company Name",
        "Expense Estimate",
      ],
    ];
    const data = arrayData.map((item, index) => [
      index + 1,
      item.fuel_station,
      item.location,
      item.expenses.company_name,
      item?.expenses.estimate,
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("FuelStationExpenseReport.pdf");
  };
  const exportToExcel = (csvData, fileName) => {
    let requriedData = [];
    let participants = [];
    let all_participants = [];
    csvData.map((row, index) => {
      requriedData.push({
        "Sr. No": index + 1,
        "Fuel Station": row.fuel_station,
        Location: row.location,
        "Company Name": row.expenses.company_name,
        "Expense Estimate": row?.expenses.estimate,
      });
    });
    const ws = XLSX.utils.json_to_sheet(requriedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div id="main">
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <header className="mb-3">
        <a href="#" className="burger-btn d-block d-xl-none">
          <i className="bi bi-justify fs-3" />
        </a>
      </header>
      <div className="page-heading">
        <div className="page-title">
          <div className="row">
            <div className="col-12 col-md-6 order-md-1 order-last">
              <h3>Fuel Station Expense Report</h3>
              <p className="text-subtitle text-muted"></p>
              <p className="text-subtitle text-muted"></p>
              <p className="text-subtitle text-muted"></p>
            </div>
            <div className="col-12 col-md-6 order-md-2 order-first">
              <nav
                aria-label="breadcrumb"
                className="breadcrumb-header float-start float-lg-end"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {currentUser?.granted_permissions_array.indexOf("report_view") >
                  -1 ===
                true ? (
                  <>
                    <button
                      style={{
                        marginLeft: 5,
                        backgroundColor: colors.primary_blue,
                        alignItems: "center",
                        justifyContent: "center",
                        borderColor: colors.primary_blue,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        exportToExcel(
                          filteredFuelStations,
                          "FuelStationExpenseReport"
                        );
                      }}
                      type="button"
                      rel="tooltip"
                      className="btn btn-primary"
                    >
                      Export to Excel
                      <i
                        style={{ marginLeft: 5 }}
                        className="bi bi-download"
                      ></i>
                    </button>
                    <button
                      style={{
                        marginLeft: 5,
                        backgroundColor: colors.primary_blue,
                        alignItems: "center",
                        justifyContent: "center",
                        borderColor: colors.primary_blue,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        exportPDF(filteredFuelStations);
                      }}
                      type="button"
                      rel="tooltip"
                      className="btn btn-primary"
                    >
                      Export to PDF
                      <i
                        style={{ marginLeft: 5 }}
                        className="bi bi-download"
                      ></i>
                    </button>
                  </>
                ) : null}
              </nav>
            </div>
          </div>
        </div>
        <DataTable
          pagination
          paginationRowsPerPageOptions={[10, 30, 50, 70, 100]}
          columns={columns}
          data={filteredFuelStations}
          paginationPerPage={100}
          progressPending={loading}
          progressComponent={<Loader />}
          striped
          dense
          customStyles={customStyles}
        />
      </div>
      <footer></footer>
    </div>
  );
}
export default FSExpenseReport;
