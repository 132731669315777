import React from "react";
//import pagenotfound image
import pagenotfoundImage from "./pagenotfound.jpg";
//react router dom
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";

const PageNotFound = () => {
  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', paddingTop:'50px'}}>
    <div className="pageNotFound">
      <h1>Oops..! 404 Page Not Found</h1>
      <p>Looks like you came to wrong page on our server</p>
      <img src={pagenotfoundImage} height="500" width="500" alt="not found" />
    </div>
    </div>
  );
};

export default PageNotFound;
