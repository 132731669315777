import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import DataTable, { defaultThemes } from "react-data-table-component";
import { colors } from "../../Helpers/ColorsPalette";
import Loader from "../../Loader/Loader";
import { Form } from "react-bootstrap";
import { Helmet } from "react-helmet";

const AllDepartmentTypes = () => {
  let history = useHistory();

  const [filterDepartmentTypes, setFilterDepartmentTypes] = useState([]);
  const [token, setToken] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [loading, setloading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [departmentTypes, setDepartmentTypes] = useState([]);
  let count = 1;
  const cuser = localStorage.getItem("user");
  let currentUser = JSON.parse(cuser);
  const [selectedStatus, setSelectedStatus] = useState(true);
  const customStyles = {
    headRow: {
      style: {
        border: "none",
        borderRadius: 10,
        paddingTop: 20,
        paddingBottom: 30,
      },
    },
    table: {
      style: {
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    responsiveWrapper: {
      style: {
        backgroundColor: "#f1f1f1",
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    tableWrapper: {
      style: {
        backgroundColor: "#f1f1f1",
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    cells: {
      style: {
        display: "flex",
        "flex-wrap": "wrap",
        "justify-content": "center",
        border: "1px solid white",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
        display: "flex",
        "flex-wrap": "wrap",
        "justify-content": "center",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        ackgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        marginTop: 2,
        border: "2px solid white",
        "border-bottom-left-radius": "25px",
        "border-bottom-right-radius": "25px",
      },
    },
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
    setloading(true);

    fetch(AdminGlobal.GETALLSTATUSES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setStatusOptions(options);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });

    fetch(AdminGlobal.GETALLDEPARTMENTTYPES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          setDepartmentTypes(responseJson.data);
          setFilterDepartmentTypes(responseJson.data);
        }
        setloading(false);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  }, []);

  const updateStatus = (id, status_id) => {
    fetch(AdminGlobal.UPDATEDepartmentTypesTATUS + id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        status_info: {
          id: status_id,
        },
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          toast.success("Status updated!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  const columns = [
    {
      name: "SrNo",
      sortable: true,
      selector: (row, index) => index + 1,
    },
    {
      name: "Name",
      sortable: true,
      sortable: true,
      selector: (row) => row?.name,
    },
    {
      name: "Status",
      // selector: row => row.seller_info.business_name,
      cell: (row) =>
        row.status_info ? (
          <Form>
            <Form.Check
              defaultChecked={row.status_info.id == 1 ? true : false}
              onChange={(e) => {
                if (e.target.checked == true) {
                  updateStatus(row.id, 1);
                } else {
                  updateStatus(row.id, 2);
                }
              }}
              style={{ display: "flex", alignItems: "center" }}
              type="switch"
              id="custom-switch"
              label="Active"
            />
          </Form>
        ) : null,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {currentUser?.granted_permissions_array.indexOf(
            "departmentType_view"
          ) >
            -1 ===
          true ? (
            <button
              style={{
                backgroundColor: colors.primary_blue,
                color: "white",
                borderColor: colors.primary_blue,
              }}
              onClick={() =>
                history.push({
                  pathname: "/departmentType/view",
                  state: {
                    id: row.id,
                  },
                })
              }
              type="button"
              rel="tooltip"
              className="btn btn-gh"
            >
              <i className="bi bi-eye-fill"></i>
            </button>
          ) : null}
          {currentUser?.granted_permissions_array.indexOf(
            "departmentType_edit"
          ) >
            -1 ===
          true ? (
            <button
              style={{
                backgroundColor: colors.primary_blue,
                color: "white",
                marginLeft: 5,
                borderColor: colors.primary_blue,
              }}
              onClick={() =>
                history.push({
                  pathname: "/departmentType/edit",
                  state: {
                    id: row.id,
                  },
                })
              }
              type="button"
              rel="tooltip"
              className="btn btn-gh"
            >
              <i className="bi bi-pencil-square"></i>
            </button>
          ) : null}
          <button
            style={{
              backgroundColor: "red",
              color: "white",
              marginLeft: 5,
              borderColor: "red",
            }}
            onClick={() => {
              if (
                window.confirm("Are you sure you want to delete this item?")
              ) {
                destroyDepartmentType(row.id);
              }
            }}
            type="button"
            rel="tooltip"
            className="btn btn-gh"
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const destroyDepartmentType = (id) => {
    fetch(AdminGlobal.DESTROYDEPARTMENTTYPE + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          toast.success(responseJson?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          window.location.reload();
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  const searchNow = (text) => {
    if (text == "") {
      setFilterDepartmentTypes(departmentTypes);
    }
    let array = departmentTypes.filter((i) => {
      if (i.name != null && i.name.toLowerCase().includes(text.toLowerCase())) {
        return i == i;
      }
    });
    setFilterDepartmentTypes(array);
  };

  return (
    <div id="main">
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <header className="mb-3">
        <a href="#" className="burger-btn d-block d-xl-none">
          <i className="bi bi-justify fs-3" />
        </a>
      </header>
      <div className="page-heading">
        <div className="page-title">
          <div className="row">
            <div className="col-12 col-md-6 order-md-1 order-last">
              <h3>All Department Types List</h3>
              <p className="text-subtitle text-muted"></p>
              <p className="text-subtitle text-muted"></p>
              <p className="text-subtitle text-muted"></p>
            </div>
            <div className="col-12 col-md-6 order-md-2 order-first">
              <nav
                aria-label="breadcrumb"
                className="breadcrumb-header float-start float-lg-end"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div
                  style={{ backgroundColor: "white" }}
                  className="form-group position-relative has-icon-right rounded"
                >
                  <input
                    type="text"
                    style={{ backgroundColor: "white" }}
                    className="form-control"
                    placeholder="Search here..."
                    onChange={(e) => {
                      searchNow(e.target.value);
                    }}
                  />
                  <div style={{ paddingTop: 0 }} className="form-control-icon">
                    <i style={{ fontSize: 20 }} className="bi bi-search" />
                  </div>
                </div>
                {currentUser?.granted_permissions_array.indexOf(
                  "departmentType_create"
                ) >
                  -1 ===
                true ? (
                  <button
                    style={{
                      marginLeft: 5,
                      backgroundColor: colors.primary_blue,
                      borderColor: colors.primary_blue,
                      alignItems: "center",
                      justifyContent: "center",
                      height: 37,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/departmentType/add");
                    }}
                    type="button"
                    rel="tooltip"
                    className="btn btn-primary"
                  >
                    Add
                    <i style={{ marginLeft: 5 }} className="bi bi-plus-lg"></i>
                  </button>
                ) : null}
              </nav>
            </div>
          </div>
        </div>

        <DataTable
          pagination
          columns={columns}
          paginationRowsPerPageOptions={[10, 30, 50, 70, 100]}
          paginationPerPage={100}
          data={filterDepartmentTypes}
          progressPending={loading}
          progressComponent={<Loader />}
          striped
          dense
          customStyles={customStyles}
        />
      </div>
      <footer></footer>
    </div>
  );
};

export default AllDepartmentTypes;
