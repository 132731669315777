import React from "react";
import { Helmet } from "react-helmet";
import { colors } from "./ColorsPalette";

function MyFooter() {
  return (
    <footer className="pb-3">
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      {/* <div className="footer clearfix mb-0 text-muted">
    <div className="float-start">
      <p>2021 © QuickPower</p>
    </div>
    <div className="float-end pr-5">
      <p>
        Powered by
        <a target={'_blank'} style={{textDecoration:'none', color:colors.primary_blue, fontWeight:'bold', marginLeft:5}} href="https://www.potatotech.co/"> Potato Tech</a>
      </p>
    </div>
  </div> */}
    </footer>
  );
}

export default MyFooter;
