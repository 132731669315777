import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import DataTable, { defaultThemes } from "react-data-table-component";
import { colors } from "../../Helpers/ColorsPalette";
import Loader from "../../Loader/Loader";
import { Button, Form } from "react-bootstrap";
import Moment from "react-moment";
import { RiFlag2Line } from "react-icons/ri";
import { Helmet } from "react-helmet";

const AllComplaints = () => {
  let history = useHistory();

  const [token, setToken] = useState("");
  const [complaintStatus, setComplaintStatus] = useState([]);
  const [selectedComplaintStatus, setSelectedComplaintStatus] = useState([]);
  const [complaintType, setComplaintType] = useState([]);
  const [selectedComplaintType, setSelectedComplaintType] = useState([]);
  const [loading, setloading] = useState(false);
  const [complaints, setComplaints] = useState([]);
  const [filterComplaints, setFilterComplaints] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(true);
  const [selectedFuelStations, setSelectedFuelStations] = useState([]);
  const [fuelStations, setFuelStations] = useState(null);

  const customStyles = {
    headRow: {
      style: {
        // border: "none",
        borderRadius: 10,
        paddingTop: 20,
        paddingBottom: 20,
      },
    },
    table: {
      style: {
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    responsiveWrapper: {
      style: {
        backgroundColor: "#f1f1f1",
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    tableWrapper: {
      style: {
        backgroundColor: "#f1f1f1",
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    cells: {
      style: {
        display: "flex",
        "flex-wrap": "wrap",
        "justify-content": "center",
        // border: "1px solid black",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
        display: "flex",
        "flex-wrap": "wrap",
        "justify-content": "center",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        marginTop: 2,
        border: "2px solid white",
        "border-bottom-left-radius": "25px",
        "border-bottom-right-radius": "25px",
      },
    },
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
    setloading(true);

    getAllComplaints(token);
    getAllFuelStations(token);
    getComplaintStatuses(token);
    getComplaintTypes(token);
    setloading(false);
  }, []);

  const getAllComplaints = (user_token) => {
    fetch(AdminGlobal.GETALLCOMPLAINTS, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user_token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          setComplaints(responseJson.data);
          setFilterComplaints(responseJson.data);
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  const getAllFuelStations = (user_token) => {
    fetch(AdminGlobal.GETALLFUELSTATIONS, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user_token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          let options = responseJson?.data.map((data) => ({
            value: data?.id,
            label: data?.name,
          }));
          setFuelStations(options);
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  const getComplaintStatuses = (user_token) => {
    fetch(AdminGlobal.GETALLCOMPLAINTSTATUSES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user_token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          let options = responseJson?.data.map((data) => ({
            value: data?.id,
            label: data?.name,
          }));
          setComplaintStatus(options);
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  const getComplaintTypes = (user_token) => {
    fetch(AdminGlobal.GETALLCOMPLAINTTYPES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user_token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          let options = responseJson?.data.map((data) => ({
            value: data?.id,
            label: data?.name,
          }));
          setComplaintType(options);
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  const columns = [
    {
      name: "Priority",
      sortable: true,
      selector: (row, index) => "priority",
      width: "3rem",
      cell: (row) => <RiFlag2Line style={{ color: "white" }} />,
      conditionalCellStyles: [
        {
          when: (row) => row.priority == "High",
          style: {
            backgroundColor: "rgba(242, 38, 19, 0.9)",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => row.priority == "Medium",
          style: {
            backgroundColor: "rgba(248, 148, 6, 0.9)",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => row.priority == "Low",
          style: {
            backgroundColor: "rgba(156,156,156, 0.9)",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "SrNo",
      sortable: true,
      selector: (row, index) => index + 1,
      width: "6rem",
    },
    {
      name: "Complaint ID",
      sortable: true,
      selector: (row) => row?.id,
      width: "8.5rem",
    },
    {
      name: "Subject",
      sortable: true,
      selector: (row) => row?.subject,
    },
    {
      name: "Details",
      selector: "details",
      sortable: true,
      //   selector: (row) => <Moment format="DD/MM/YYYY">{row?.created_at}</Moment>,
      cell: (row) => (
        <p
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            alignSelf: "flex-end",
            // width: "200px",
          }}
        >
          {row?.details}
        </p>
      ),
    },
    {
      name: "Complaint Type",
      sortable: true,
      selector: (row) => row?.complaint_type?.name,
    },
    {
      name: "Branch",
      sortable: true,
      selector: (row) => row?.fuel_station?.name,
    },
    {
      name: "Initiated On",
      selector: "created_at",
      sortable: true,
      //   selector: (row) => <Moment format="DD/MM/YYYY">{row?.created_at}</Moment>,
      cell: (row) => <Moment format="DD/MM/YYYY">{row?.created_at}</Moment>,
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row?.complaint_status?.name,
      width: "6rem",
    },
    {
      name: "Action",
      // selector: row => row.seller_info.business_name,
      cell: (row) => (
        <div>
          <button
            style={{
              backgroundColor: colors.primary_blue,
              color: "white",
              borderColor: colors.primary_blue,
            }}
            onClick={() =>
              history.push({
                pathname: "/complaint/view",
                state: {
                  id: row.id,
                },
              })
            }
            type="button"
            rel="tooltip"
            className="btn btn-gh"
          >
            <i className="bi bi-eye-fill"></i>
          </button>
        </div>
      ),
    },
  ];

  // const conditionalRowStyles = [
  // {
  //   when: (row) => row.priority == "High",
  //   style: {
  //     backgroundColor: "rgba(242, 38, 19, 0.9)",
  //     color: "white",
  //     "&:hover": {
  //       cursor: "pointer",
  //     },
  //   },
  // },
  // {
  //   when: (row) => row.priority == "Medium",
  //   style: {
  //     backgroundColor: "rgba(248, 148, 6, 0.9)",
  //     color: "white",
  //     "&:hover": {
  //       cursor: "pointer",
  //     },
  //   },
  // },
  // {
  //   when: (row) => row.priority == "Low",
  //   style: {
  //     backgroundColor: "rgba(220,220,220, 0.9)",
  //     color: "white",
  //     "&:hover": {
  //       cursor: "pointer",
  //     },
  //   },
  // },
  // ];

  const searchNow = (text) => {
    if (text == "") {
      setFilterComplaints(complaints);
    } else {
      let array = complaints.filter((i) => {
        if (
          (i.subject != null &&
            i?.subject.toLowerCase().includes(text.toLowerCase())) ||
          i?.complaint_type?.name.toLowerCase().includes(text.toLowerCase()) ||
          i?.fuel_station?.name.toLowerCase().includes(text.toLowerCase()) ||
          i?.complaint_status?.name.toLowerCase().includes(text.toLowerCase())
        ) {
          return i == i;
        }
      });
      setFilterComplaints(array);
    }
  };

  const filter = () => {
    setloading(true);
    let fuel_station_ids = selectedFuelStations.map((item) => item.value);
    let complaint_status_ids = selectedComplaintStatus.map(
      (item) => item.value
    );
    let complaint_type_ids = selectedComplaintType.map((item) => item.value);

    fetch(AdminGlobal.FILTERCOMPLAINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fuel_station_ids: fuel_station_ids,
        complaint_status_ids: complaint_status_ids,
        complaint_type_ids: complaint_type_ids,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setComplaints(responseJson.data);
        setFilterComplaints(responseJson.data);
        setloading(false);
      })
      .catch((error) => {
        //Hide Loader
        setloading(false);
        console.error(error);
      });
  };

  return (
    <div id="main">
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <header className="mb-3">
        <a href="#" className="burger-btn d-block d-xl-none">
          <i className="bi bi-justify fs-3" />
        </a>
      </header>
      <div className="page-heading">
        <div className="page-title">
          <div className="row">
            <div className="col-12 col-md-4 order-md-1 order-last">
              <h3>All Complaints List</h3>
            </div>
            <div className="col-12 col-md-8 order-md-2 order-first">
              <nav
                aria-label="breadcrumb"
                className="breadcrumb-header float-start float-lg-end"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <div className="form-group position-relative has-icon-right rounded">
                  <input
                    type="text"
                    style={{ backgroundColor: "white" }}
                    className="form-control"
                    placeholder="Search here..."
                    onChange={(e) => {
                      searchNow(e.target.value);
                    }}
                  />
                  <div style={{ paddingTop: 0 }} className="form-control-icon">
                    <i style={{ fontSize: 20 }} className="bi bi-search" />
                  </div>
                </div>
              </nav>
            </div>
            <div
              className="col-12 col-md-12 order-md-2 order-first"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <div
                className="form-group"
                style={{ marginRight: "1%", width: "100%" }}
              >
                <Select
                  placeholder="Select Fuel Stations"
                  onChange={(selected) => {
                    setSelectedFuelStations(selected);
                  }}
                  isMulti={true}
                  options={fuelStations}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                />
              </div>
              <div
                className="form-group"
                style={{ marginRight: "1%", width: "100%" }}
              >
                <Select
                  placeholder="Select Complaint Status"
                  onChange={(selected) => {
                    setSelectedComplaintStatus(selected);
                  }}
                  isMulti={true}
                  options={complaintStatus}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                />
              </div>
              <div
                className="form-group"
                style={{ marginRight: "1%", width: "100%" }}
              >
                <Select
                  placeholder="Select Complaint Type"
                  onChange={(selected) => {
                    setSelectedComplaintType(selected);
                  }}
                  isMulti={true}
                  options={complaintType}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                />
              </div>
              <Button
                style={{ marginRight: "1%" }}
                onClick={() => {
                  filter();
                }}
              >
                <i className="bi bi-filter" style={{ marginTop: -10 }} />
                Filter
              </Button>
              <Button
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 20,
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
              padding: 5,
              marginRight: 2,
            }}
          >
            <RiFlag2Line style={{ color: "white" }} />
            <span style={{ color: "white", fontWeight: "bold" }}>High</span>
          </div>
          <div
            style={{
              backgroundColor: "rgba(248, 148, 6, 0.9)",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
              padding: 5,
              marginRight: 2,
            }}
          >
            <RiFlag2Line style={{ color: "white" }} />
            <span style={{ color: "white", fontWeight: "bold" }}>Medium</span>
          </div>
          <div
            style={{
              backgroundColor: "rgba(156,156,156, 0.9)",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
              padding: 5,
            }}
          >
            <RiFlag2Line style={{ color: "white" }} />
            <span style={{ color: "white", fontWeight: "bold" }}>Low</span>
          </div>
        </div>

        {loading === false ? (
          <DataTable
            pagination
            columns={columns}
            paginationRowsPerPageOptions={[100, 200, 300, 400, 500]}
            paginationPerPage={100}
            data={filterComplaints}
            progressPending={loading}
            progressComponent={<Loader />}
            striped
            dense
            // conditionalRowStyles={conditionalRowStyles}
            customStyles={customStyles}
          />
        ) : (
          <div className="col-md-12">
            <Loader />
          </div>
        )}
      </div>
      <footer></footer>
    </div>
  );
};

export default AllComplaints;
