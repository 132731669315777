import { React, useEffect, useState, Fragment } from "react";
import { Formik } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Loader from "../../Loader/Loader";
import * as Yup from "yup";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { colors } from "../../Helpers/ColorsPalette";

const ViewRole = () => {
  let history = useHistory();
  let location = useLocation();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [roleName, setRoleName] = useState("");
  const [userTypes, setUserTypes] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [selectedUserTypeName, setSelectedUserTypeName] = useState();
  const [selectedStatusName, setSelectedStatusName] = useState();
  const [permissions, setPermissions] = useState([]);
  const [modules, setModules] = useState([]);
  const [loaded, setloaded] = useState(false);
  const [currentRoleName, setCurrentRoleName] = useState("");
  const [currentSelectedUserType, setCurrentSelectedUserType] = useState("");
  const [currentSelectedUserTypeName, setCurrentSelectedUserTypeName] =
    useState("");
  const [currentSelectedStatusName, setCurrentSelectedStatusName] =
    useState("");
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const [responseJson, setResponseJson] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissons] = useState([]);
  const [grantedPermissions, setGrantedPermissions] = useState([]);

  const detectUserType = async () => {
    const user = localStorage.getItem("user");
    return JSON.parse(user);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken("Bearer " + token);
    setLoading(true);
    //Status API
    fetch(AdminGlobal.ROLEPREREQ, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const perm = responseJson.permissions.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setAllPermissions(responseJson.permissions);
        // setCurrentPermissions(responseJson.permissions)

        const statuses = responseJson.statuses.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        const user_types = responseJson.user_types.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setStatus(statuses);
        setUserTypes(user_types);
        setPermissions(perm);
        setModules(responseJson.modules);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });

    fetch(AdminGlobal.GETROLEBYID + location.state.id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setCurrentRoleName(responseJson.data.name);
        setCurrentSelectedUserType(responseJson.data.user_type_id);
        const user_types = {
          value: responseJson.data.user_type_id,
          label: responseJson.data.user_type,
        };
        setCurrentSelectedUserTypeName(user_types);
        setCurrentSelectedStatusName(responseJson.data.status_info);
        setSelectedStatus(responseJson.data.status_info.value);
        setCurrentPermissions(responseJson.data.permissions);
        setSelectedPermissons(responseJson.data.permissions);
        setResponseJson(responseJson);
        setloaded(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log("error: ", e);
      });
  }, []);

  useEffect(() => {
    return () => {};
  }, [selectedPermissions]);

  const save = (values) => {
    if (currentSelectedUserTypeName.value == "") {
      toast.error("Please select user type!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (currentSelectedStatusName.value == "") {
      toast.error("Please select status!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    let permission_array = new Array();
    selectedPermissions.map((element) => {
      permission_array.push({
        id: element,
      });
    });

    if (permission_array.length <= 0) {
      toast.error("Please select at least 1 permission!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    fetch(AdminGlobal.EDITROLE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        role: { id: location.state.id },
        name: values.name,
        role_type: { id: currentSelectedUserType },
        status_info: { id: selectedStatus },
        permission: selectedPermissions,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          toast.success("Saved!", {
            position: toast.POSITION.TOP_CENTER,
          });
          history.goBack();
        } else {
          toast.error("There seems to be a problem", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  const roleSchema = Yup.object({
    role_name: Yup.string()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
  });

  const errors = {
    textAlign: "right",
    fontSize: 14,
    color: "red",
    fontWeight: "bold",
    borderTopWidth: 0.8,
    borderColor: "red",
    borderRadius: 8,
  };

  function autoCapitalize(str) {
    str = str.replace("_", " ");
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div id="main" style={{ fontFamily: "Nunito" }}>
      <ToastContainer />
      <div className="row">
        <div
          style={{
            backgroundColor: "rgb(240, 240, 240,0.2)",
            color: colors.primary_blue,
          }}
          className="card-header card-header-rose card-header-text my-4"
        >
          <div className="card-text">
            <h4 className="card-title">View Role</h4>
          </div>
        </div>

        <div className="col-md-12">
          {loading == true ? (
            <Loader />
          ) : loaded == true ? (
            <Formik
              initialValues={{
                role_name: currentRoleName,
                selected_user_type: currentSelectedUserTypeName.value,
                selected_status: currentSelectedStatusName.value,
                selected_permission: "",
              }}
              validationSchema={roleSchema}
              onSubmit={(values, { setSubmitting }) => {
                // setSubmitting(true);
                save(values);
              }}
            >
              {(props) => (
                <form
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      e.preventDefault();
                    }
                  }}
                  onSubmit={(e) => e.preventDefault()}
                  className="form-horizontal"
                >
                  <div
                    className="card"
                    style={{
                      border: "solid 0px white",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="card-body ">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Role Name <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              disabled
                              defaultValue={currentRoleName}
                              onChange={props.handleChange("role_name")}
                              type="rel"
                              className="form-control"
                            />
                            {props.touched.role_name &&
                            props.errors.role_name ? (
                              <div style={errors}>
                                {props.touched.role_name &&
                                  props.errors.role_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {currentSelectedUserTypeName != "" ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                Type <b style={{ color: "red" }}>*</b>
                              </label>
                              <Select
                                defaultValue={{
                                  value: currentSelectedUserTypeName.value,
                                  label: currentSelectedUserTypeName.label,
                                }}
                                onChange={(selected) => {
                                  setSelectedUserTypeName(selected.label);
                                  setSelectedUserType(selected.value);
                                }}
                                isDisabled={true}
                                options={userTypes}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                        {currentSelectedStatusName != "" ? (
                          <>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="basicInput">
                                  Status <b style={{ color: "red" }}>*</b>
                                </label>
                                <Select
                                  isDisabled
                                  defaultValue={{
                                    value: currentSelectedStatusName.value,
                                    label: currentSelectedStatusName.label,
                                  }}
                                  // placeholder={selectedStatusName}
                                  onChange={(selected) => {
                                    // values.selected_status = selected.value;
                                    setSelectedStatus(selected.value);
                                    setSelectedStatusName(selected.label);
                                  }}
                                  options={status}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgb(240, 240, 240,0.2)",
                      color: colors.primary_blue,
                    }}
                    className="card-header card-header-rose card-header-text my-4"
                  >
                    <div className="card-text">
                      <h4 className="card-title">View Permissions</h4>
                    </div>
                  </div>
                  <div
                    className="card"
                    style={{
                      border: "solid 0px white",
                      borderRadius: "10px",
                    }}
                  >
                    {responseJson.status == 200 ? (
                      <div className="card-body ">
                        <div className="row col-sm-12">
                          <div
                            className="col-sm-4 col-form-label"
                            style={{
                              textAlign: "left",
                              fontWeight: "bolder",
                              fontSize: "2vw",
                              padding: "2%",
                            }}
                          >
                            All Permissions
                          </div>
                          <div
                            className="row col-sm-8"
                            style={{
                              border: "1px",
                              // borderStyle: "groove",
                            }}
                          >
                            <div
                              className="col-sm-3"
                              style={{ alignSelf: "center", marginLeft: -5 }}
                            >
                              <div className="form-group">
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    fontSize: "1.5vw",
                                  }}
                                >
                                  {" "}
                                </span>
                              </div>
                            </div>
                            <div
                              className="col-sm-3"
                              style={{
                                alignSelf: "center",
                                textAlign: "left",
                              }}
                            >
                              <div className="form-group">
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    fontSize: "1.5vw",
                                  }}
                                >
                                  Create
                                </span>
                              </div>
                            </div>
                            <div
                              className="col-sm-3"
                              style={{
                                alignSelf: "center",
                                textAlign: "left",
                              }}
                            >
                              <div className="form-group">
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    fontSize: "1.5vw",
                                  }}
                                >
                                  Edit
                                </span>
                              </div>
                            </div>
                            <div
                              className="col-sm-3"
                              style={{ alignSelf: "center", marginLeft: -5 }}
                            >
                              <div className="form-group">
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    fontSize: "1.5vw",
                                  }}
                                >
                                  View
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {modules.length > 0
                          ? modules.map((object) => {
                              return (
                                <Fragment key={object.id.toString()}>
                                  <div
                                    key={object.id.toString()}
                                    className="row col-sm-12"
                                  >
                                    <div
                                      className="col-sm-4 col-form-label"
                                      style={{
                                        textAlign: "left",
                                        fontSize: "1.2vw",
                                        padding: "2%",
                                        borderWidth: "1px",
                                        // borderStyle: "groove",
                                      }}
                                    >
                                      {object.name}
                                    </div>
                                    <div
                                      className="row col-sm-8"
                                      style={{
                                        borderWidth: "1px",
                                        // borderStyle: "groove",
                                      }}
                                    >
                                      <div
                                        className="col-sm-3"
                                        style={{ alignSelf: "center" }}
                                      >
                                        <div
                                          style={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {/* <input 
                                    type="checkbox"
                                    onClick={(e)=>{
                                      object.permissions.map((val)=>{
                                        const checked =
                                                  e.target.checked;
                                                if (checked === true) {
                                                  selectedPermissions.push(
                                                    val.id.toString()
                                                  );
                                                } else {
                                                  var a =
                                                    selectedPermissions.indexOf(
                                                      val.id.toString()
                                                    );
                                                  selectedPermissions.splice(
                                                    a,
                                                    1
                                                  );
                                                }
                                      })
                                    }} 
                                    /> */}
                                        </div>
                                      </div>
                                      {object.permissions
                                        .sort((a, b) =>
                                          a.name.localeCompare(b.name)
                                        )
                                        .map((element) => {
                                          let checked = false;
                                          currentPermissions.map(
                                            (current_permissions) => {
                                              if (
                                                current_permissions.id ==
                                                element.id
                                              ) {
                                                checked = true;
                                              }
                                            }
                                          );
                                          console.log(modules);
                                          return (
                                            <Fragment
                                              key={element.id.toString()}
                                            >
                                              <div
                                                className="col-sm-3"
                                                style={{
                                                  alignSelf: "center",
                                                }}
                                              >
                                                <div className="form-group">
                                                  <input
                                                    disabled
                                                    type="checkbox"
                                                    value={element.id}
                                                    defaultChecked={checked}
                                                    onClick={(e) => {
                                                      const checked =
                                                        e.target.checked;
                                                      if (checked === true) {
                                                        if (
                                                          selectedPermissions.some(
                                                            (data) =>
                                                              data.id ==
                                                              e.target.value
                                                          ) == false
                                                        ) {
                                                          let obj = {};
                                                          allPermissions.map(
                                                            (data) => {
                                                              if (
                                                                data.id ==
                                                                e.target.value
                                                              ) {
                                                                obj = data;
                                                              }
                                                            }
                                                          );
                                                          selectedPermissions.push(
                                                            obj
                                                          );
                                                        }
                                                      } else {
                                                        let obj = {};
                                                        selectedPermissions.map(
                                                          (data) => {
                                                            if (
                                                              data.id ==
                                                              e.target.value
                                                            ) {
                                                              obj = data;
                                                            }
                                                          }
                                                        );

                                                        let array =
                                                          selectedPermissions.filter(
                                                            (val) => {
                                                              return val != obj;
                                                            }
                                                          );
                                                        setSelectedPermissons(
                                                          array
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  <span
                                                    style={{
                                                      fontSize: "0.8vw",
                                                    }}
                                                  >
                                                    {"     "}
                                                    {/* {autoCapitalize(
                                                      element.name.replace(
                                                        "_",
                                                        " "
                                                      )
                                                    )} */}
                                                  </span>
                                                </div>
                                              </div>
                                            </Fragment>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </Fragment>
                              );
                              // }
                            })
                          : null}
                      </div>
                    ) : (
                      <Loader />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: colors.primary_blue,
                        color: "white",
                      }}
                      className="btn btn-gh rounded-pill"
                      onClick={(e) => {
                        e.preventDefault();
                        history.goBack();
                      }}
                    >
                      Back
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ViewRole;
