// import logo from "./logo.svg";
import "./../../App.css";
import { React, useEffect, useState } from "react";
import pagebg from "./crmbg.png";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import Dashboard from "../Main/Dashboard/Dashboard";
import AdminDrawer from "../Main/Layout/Admindrawer";
import UserProfile from "../Main/Settings/UserProfile";
import Login from "../Auth/Login";
import PageNotFound from "../Main/PageNotFound/PageNotFound";
import MyFooter from "../Helpers/MyFooter";
import AllUsers from "../Main/Users/AllUsers";
import AddUser from "../Main/Users/AddUser";
import AllFuelStations from "../Main/FuelStations/AllFuelStations";
import AddFuelStation from "../Main/FuelStations/AddFuelStation";
import AllCategories from "../Main/Categories/AllCategories";
import AddCategory from "../Main/Categories/AddCategory";
import EditCategory from "../Main/Categories/EditCategory";
import ViewCategory from "../Main/Categories/ViewCategory";
import EditFuelStation from "../Main/FuelStations/EditFuelStation";
import EditUser from "../Main/Users/EditUser";
import ViewUser from "../Main/Users/ViewUser";
import NotAuthorised from "../Main/NotAuthorised/NotAuthorised";
import ViewFuelStation from "../Main/FuelStations/ViewFuelStation";
import ComplaintsReport from "../Main/Reportings/ComplaintsReport";
import CategoryReport from "../Main/Reportings/CategoryReport";
import CategoryExpenseReport from "../Main/Reportings/CategoryExpenseReport";
import FSExpenseReport from "../Main/Reportings/FSExpenseReport";

import AllDepartmentTypes from "../Main/Department/AllDepartmentTypes";
import ViewDepartmentType from "../Main/Department/ViewDepartmentType";
import EditDepartmentType from "../Main/Department/EditDepartmentType";
import AddDepartmentType from "../Main/Department/AddDepartmentType";
import AllComplaints from "../Main/Complaints/AllComplaints";
import ViewComplaint from "../Main/Complaints/ViewComplaint";
import AllRoles from "../Main/Roles/AllRoles";
import AddRole from "../Main/Roles/AddRole";
import ViewRole from "../Main/Roles/ViewRole";
import EditRole from "../Main/Roles/EditRole";

const detectLogin = async () => {
  const token = localStorage.getItem("token");
  return token;
};

const AdminRoutes = () => {
  const [token, setToken] = useState("");
  const cuser = localStorage.getItem("user");
  let currentUser = JSON.parse(cuser);

  useEffect(() => {
    detectLogin().then((res) => {
      setToken(res);
      if (res === "" || res === null) {
        setToken(null);
      } else {
        setToken(res);
      }
    });
  }, []);

  return (
    <Router>
      <Switch>
        {/* -------------------------------------------------------------------------------------------------------
------------------- Routes for View components --------------------------------------------------------
------------------------------------------------------------------------------------------------------- */}

        <Route
          exact
          path="/"
          render={() => {
            const currentToken = localStorage.getItem("token");
            return currentToken !== null && currentToken !== "" ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/login" />
            );
          }}
        />

        <Route exact path={"/login"}>
          {localStorage.getItem("token") != null ? (
            <Redirect to={"/dashboard"} />
          ) : (
            <Login />
          )}
        </Route>

        <Route exact path="/dashboard">
          <div
            style={{
              backgroundColor: "#f1f1f1",
              minHeight: "100vh",
              maxHeight: "auto",
            }}
            id="app"
          >
            <AdminDrawer />
            <Dashboard />
            <MyFooter />
          </div>
        </Route>

        <Route exact path="/users">
          {currentUser?.granted_permissions_array.indexOf("user_view") > -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <AllUsers />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/users/add">
          {currentUser?.granted_permissions_array.indexOf("user_create") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <AddUser />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/users/edit">
          {currentUser?.granted_permissions_array.indexOf("user_edit") > -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <EditUser />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/users/view">
          {currentUser?.granted_permissions_array.indexOf("user_view") > -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <ViewUser />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/fuelstations">
          {currentUser?.granted_permissions_array.indexOf("fuelStation_view") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <AllFuelStations />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/fuelstations/add">
          {currentUser?.granted_permissions_array.indexOf(
            "fuelStation_create"
          ) >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <AddFuelStation />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/fuelstations/edit">
          {currentUser?.granted_permissions_array.indexOf("fuelStation_edit") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <EditFuelStation />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/fuelstations/view">
          {currentUser?.granted_permissions_array.indexOf("fuelStation_view") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <ViewFuelStation />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/categories">
          {currentUser?.granted_permissions_array.indexOf("category_view") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <AllCategories />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/categories/add">
          {currentUser?.granted_permissions_array.indexOf("category_create") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <AddCategory />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/categories/edit">
          {currentUser?.granted_permissions_array.indexOf("category_edit") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <EditCategory />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/categories/view">
          {currentUser?.granted_permissions_array.indexOf("category_view") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <ViewCategory />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/complaintsreport">
          {currentUser?.granted_permissions_array.indexOf("report_view") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <ComplaintsReport />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/categoryreports">
          {currentUser?.granted_permissions_array.indexOf("report_view") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <CategoryReport />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/categoryexpensereport">
          {currentUser?.granted_permissions_array.indexOf("report_view") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <CategoryExpenseReport />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/fsexpensereport">
          {currentUser?.granted_permissions_array.indexOf("report_view") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <FSExpenseReport />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/departmentType">
          {currentUser?.granted_permissions_array.indexOf(
            "departmentType_view"
          ) >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <AllDepartmentTypes />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/departmentType/view">
          {currentUser?.granted_permissions_array.indexOf(
            "departmentType_view"
          ) >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <ViewDepartmentType />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/departmentType/edit">
          {currentUser?.granted_permissions_array.indexOf(
            "departmentType_edit"
          ) >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <EditDepartmentType />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/departmentType/add">
          {currentUser?.granted_permissions_array.indexOf(
            "departmentType_view"
          ) >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <AddDepartmentType />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/complaint">
          {currentUser?.granted_permissions_array.indexOf("complaint_view") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <AllComplaints />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/complaint/view">
          {currentUser?.granted_permissions_array.indexOf("complaint_view") >
            -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <ViewComplaint />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/roles">
          {currentUser?.granted_permissions_array.indexOf("role_create") >
            -1 ===
            true ||
          currentUser?.granted_permissions_array.indexOf("role_edit") > -1 ===
            true ||
          currentUser?.granted_permissions_array.indexOf("role_view") > -1 ===
            true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <AllRoles />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/roles/add">
          {currentUser?.granted_permissions_array.indexOf("role_create") >
            -1 ===
            true ||
          currentUser?.granted_permissions_array.indexOf("role_view") > -1 ===
            true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <AddRole />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/roles/view">
          {currentUser?.granted_permissions_array.indexOf("role_view") > -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <ViewRole />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route exact path="/roles/edit">
          {currentUser?.granted_permissions_array.indexOf("role_edit") > -1 ===
          true ? (
            <div
              style={{
                backgroundColor: "#f1f1f1",
                minHeight: "100vh",
                maxHeight: "auto",
              }}
              id="app"
            >
              <AdminDrawer />
              <EditRole />
              <MyFooter />
            </div>
          ) : (
            <NotAuthorised />
          )}
        </Route>

        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default AdminRoutes;
