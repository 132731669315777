import { project_type } from "./AdminGlobal";

var LiveServer = "https://backend.quickpower.sa/public/api/";
var TestingServer = "https://backend.quickpower.sa/public/api/";

var link = project_type == "live" ? LiveServer : TestingServer;

export const GeneralGlobal = {
  FORGOTPASSWORD: link + "forgotPassword",
  LOGIN: link + "login",
  SELLERREGISTER: link + "postsellerRegister",
  RESETPASSWORD: link + "forgotPassword",
  GETALLPRODUCTS: link + "getAllProducts",
  VERIFYSLUG: link + "verifySlug",
  GETBANNERS: link + "reseller/getAllBanners",
};
