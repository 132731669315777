import logo from "./logo.svg";
import "./App.css";
import { React, useEffect, useState } from "react";
import AdminRoutes from "./components/Navigation/AdminRoutes";
import Login from "./components/Auth/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [maintenance, setMaintenance] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  let project_name = localStorage.getItem("project_name");

  return maintenance ? (
    <div
      style={{
        height: "100%",
        padding: "5%",
        alignItems: "center",
        width: "100%",
      }}
    >
      <h2 style={{ fontWeight: "bold" }}>
        Sorry! We are under maintenance currently!!
      </h2>
    </div>
  ) : project_name == "quickpower" ? (
    <AdminRoutes />
  ) : (
    <Router>
      <Switch>
        {/* -------------------------------------------------------------------------------------------------------
------------------- Routes for View components --------------------------------------------------------
------------------------------------------------------------------------------------------------------- */}
        <Route
          exact
          path="/"
          render={() => {
            const currentToken = localStorage.getItem("token");
            return <Redirect to="/login" />;
          }}
        />

        <Route exact path="/login">
          <div id="app">
            <Login />
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
