import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Loader from "../../Loader/Loader";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { colors } from "../../Helpers/ColorsPalette";
import ProgressLoader from "react-loader-spinner";
import { Helmet } from "react-helmet";

const AddUser = () => {
  let history = useHistory();
  const [token, setToken] = useState(null);
  const [loading, setloading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [allStatuses, setAllStatuses] = useState([]);
  const [dob, setDob] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [departmentTypes, setDepartmentTypes] = useState([]);
  const [selectedDepartmentType, setSelectedDepartmentType] = useState(null);
  const [fuelStations, setFuelStations] = useState([]);
  const [selectedFuelStation, setSelectedFuelStation] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [selectedGender, setSelectedGender] = useState({});

  const [allRoles, setAllRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  const gender = [
    {
      value: 1,
      label: "Male",
    },
    {
      value: 2,
      label: "Female",
    },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken("Bearer " + token);

    fetch(AdminGlobal.GETALLCITIES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setAllCities(options);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });

    fetch(AdminGlobal.GETALLUSERTYPES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setUserTypes(options);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });

    fetch(AdminGlobal.GETALLDEPARTMENTTYPES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setDepartmentTypes(options);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });

    fetch(AdminGlobal.GETALLFUELSTATIONS, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setFuelStations(options);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });

    fetch(AdminGlobal.GETALLROLES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.roles.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setAllRoles(options);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  }, []);

  const errors = {
    textAlign: "right",
    fontSize: 14,
    color: "red",
    fontWeight: "bold",
    borderTopWidth: 0.8,
    borderColor: "red",
    borderRadius: 8,
  };

  const addUser = (values) => {
    // console.log({
    //   first_name: values.first_name,
    //   last_name: values.last_name,
    //   user_name: values.user_name,
    //   email: values.email,
    //   address: values.address,
    //   date_of_birth: dob,
    //   password: values.password,
    //   contact_number: values.mobile_number,
    //   gender: selectedGender,
    //   nationality_city_id: selectedCity.value,
    //   user_type: { id: selectedUserType.value },
    //   department_type: selectedDepartmentType,
    //   fuel_stations: selectedFuelStation,
    //   selectedRole: selectedRole.value,
    // });

    if (selectedUserType.value == 1 && selectedRole.value == null) {
      toast.error("Please select a role!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    setSubmitLoader(true);
    fetch(AdminGlobal.ADDUSER, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: values?.first_name,
        last_name: values?.last_name,
        user_name: values?.user_name,
        email: values?.email,
        address: values?.address,
        date_of_birth: dob,
        password: values?.password,
        contact_number: values?.mobile_number,
        gender: selectedGender,
        nationality_city_id: selectedCity?.value,
        user_type: { id: selectedUserType?.value },
        department_types: selectedDepartmentType,
        fuel_stations: selectedFuelStation,
        selectedRole: selectedRole?.value,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          toast.success("Saved!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            history.push("/users");
          }, 2000);
        } else {
          toast.error("There seems to be an error!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setSubmitLoader(false);
      })
      .catch((error) => {
        //Hide Loader
        setSubmitLoader(false);
        console.error(error);
      });
  };

  return (
    <div id="main" style={{ fontFamily: "Nunito" }}>
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <div className="row">
        <div
          style={{
            backgroundColor: "rgb(240, 240, 240,0.2)",
            color: colors.primary_blue,
          }}
          className="card-header card-header-rose card-header-text my-4"
        >
          <div className="card-text">
            <h4 className="card-title boldHeading">Add User</h4>
          </div>
        </div>
        <div className="col-md-12">
          <div
            className="card"
            style={{ border: "solid 0px white", borderRadius: "10px" }}
          >
            {loading == false ? (
              <div className="card-body ">
                <Formik
                  initialValues={{
                    first_name: "",
                    last_name: "",
                    gender: "",
                    user_name: "",
                    mobile_number: "",
                    email: "",
                    address: "",
                    password: "",
                  }}
                  validationSchema={Yup.object({
                    first_name: Yup.string().required("Required"),
                    last_name: Yup.string().required("Required"),
                    user_name: Yup.string().required("Required"),
                    mobile_number: Yup.string().required("Required"),
                    email: Yup.string().required("Required"),
                    address: Yup.string().required("Required"),
                    password: Yup.string().required("Required"),
                  })}
                  onSubmit={(values) => {
                    addUser(values);
                  }}
                >
                  {(props) => (
                    <form
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          e.preventDefault();
                        }
                      }}
                      className="form-horizontal"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              First Name <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("first_name")}
                              type="tel"
                              className="form-control"
                              required
                            />
                            {props.touched.first_name &&
                            props.errors.first_name ? (
                              <div style={errors}>
                                {props.touched.first_name &&
                                  props.errors.first_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Last Name <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("last_name")}
                              type="tel"
                              className="form-control"
                              required
                            />
                            {props.touched.last_name &&
                            props.errors.last_name ? (
                              <div style={errors}>
                                {props.touched.last_name &&
                                  props.errors.last_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Gender <b style={{ color: "red" }}>*</b>
                            </label>
                            <Select
                              placeholder="Select Gender"
                              onChange={(selected) => {
                                setSelectedGender(selected);
                              }}
                              isDisabled={gender.length > 0 ? false : true}
                              options={gender}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            {gender == null && submitted == true ? (
                              <div style={errors}>Required</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Username <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("user_name")}
                              type="tel"
                              className="form-control"
                              required
                            />
                            {props.touched.user_name &&
                            props.errors.user_name ? (
                              <div style={errors}>
                                {props.touched.user_name &&
                                  props.errors.user_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Mobile No <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("mobile_number")}
                              type="tel"
                              className="form-control"
                              required
                            />
                            {props.touched.mobile_number &&
                            props.errors.mobile_number ? (
                              <div style={errors}>
                                {props.touched.mobile_number &&
                                  props.errors.mobile_number}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Email <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("email")}
                              type="email"
                              className="form-control"
                              required
                            />
                            {props.touched.email && props.errors.email ? (
                              <div style={errors}>
                                {props.touched.email && props.errors.email}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Date of Birth <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={(e) => setDob(e.target.value)}
                              type="date"
                              className="form-control"
                              required
                            />
                            {dob == null && submitted == true ? (
                              <div style={errors}>Required</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              City <b style={{ color: "red" }}>*</b>
                            </label>
                            <Select
                              placeholder="Select City"
                              onChange={(selected) => {
                                setSelectedCity(selected);
                              }}
                              isDisabled={allCities.length > 0 ? false : true}
                              options={allCities}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            {selectedCity == null && submitted == true ? (
                              <div style={errors}>Required</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Address <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("address")}
                              type="tel"
                              defaultValue={""}
                              autoComplete="off"
                              className="form-control"
                            />
                            {props.touched.address && props.errors.address ? (
                              <div style={errors}>
                                {props.touched.address && props.errors.address}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Password <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("password")}
                              type="password"
                              autoComplete="new-password"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              User Type <b style={{ color: "red" }}>*</b>
                            </label>
                            <Select
                              placeholder="Select User Type"
                              onChange={(selected) => {
                                setSelectedUserType(selected);
                                if (selected.value != 4) {
                                  setSelectedFuelStation([]);
                                }
                                if (selected.value == 1) {
                                  setSelectedFuelStation([]);
                                  setSelectedDepartmentType([]);
                                }
                              }}
                              isDisabled={userTypes.length > 0 ? false : true}
                              options={userTypes}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            {selectedUserType == null && submitted == true ? (
                              <div style={errors}>Required</div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {selectedUserType?.value == 4 ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                Branches <b style={{ color: "red" }}>*</b>
                              </label>
                              <Select
                                placeholder="Assign Branches"
                                onChange={(selected) => {
                                  setSelectedFuelStation(selected);
                                }}
                                isDisabled={
                                  fuelStations.length > 0 ? false : true
                                }
                                isMulti={true}
                                options={fuelStations}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                              {selectedFuelStation == null &&
                              submitted == true ? (
                                <div style={errors}>Required</div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                        {selectedUserType?.value != 1 ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                Department Type{" "}
                                <b style={{ color: "red" }}>*</b>
                              </label>
                              <Select
                                placeholder="Select Department Type"
                                onChange={(selected) => {
                                  setSelectedDepartmentType(selected);
                                }}
                                isDisabled={
                                  departmentTypes.length > 0 ? false : true
                                }
                                isMulti={true}
                                options={departmentTypes}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                              {selectedDepartmentType == null &&
                              submitted == true ? (
                                <div style={errors}>Required</div>
                              ) : null}
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                Role
                                <b style={{ color: "red" }}>*</b>
                              </label>
                              <Select
                                placeholder="Select Role"
                                onChange={(selected) => {
                                  setSelectedRole(selected);
                                }}
                                options={allRoles}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                              {selectedDepartmentType == null &&
                              submitted == true ? (
                                <div style={errors}>Required</div>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                          }}
                          className="btn btn-gh rounded-pill"
                          onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                          }}
                        >
                          Back
                        </button>
                        <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                            marginLeft: 5,
                          }}
                          type="submit"
                          className="btn btn-fill btn-gh rounded-pill"
                          // onClick={props.handleSubmit}
                          onClick={(e) => {
                            e.preventDefault();
                            setSubmitted(true);
                            props.handleSubmit();
                          }}
                        >
                          {submitLoader == true ? (
                            <ProgressLoader
                              color={"white"}
                              height={50}
                              width={50}
                              type={"Oval"}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
