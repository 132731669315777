import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Helmet } from "react-helmet";
// import Loader from "react-loader-spinner";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { colors } from "../../Helpers/ColorsPalette";
import Select from "react-select";
import "chart.js/auto";
import { Button } from "react-bootstrap";
import Loader from "../../Loader/Loader";
import { ToastContainer, toast } from "react-toastify";

const Dashboard = () => {
  const [token, setToken] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [fuelStations, setFuelStations] = useState([]);
  const [profile, setProfile] = useState(null);
  const [fuelStationNameLabels, setFuelStationNameLabels] = useState([]);
  const [categoryNameLabels, setCategoryNameLabels] = useState([]);
  const [departmentTypeLabels, setDepartmentTypeLabels] = useState([]);
  const [pieChartColors, setPieChartColors] = useState([]);
  const [pieChartEstimatesOfExpense, setPieChartEstimateOfExpense] = useState(
    []
  );
  const [pieChartCategoryOccurance, setPieChartCategoryOccurance] = useState(
    []
  );
  const [pieChartDepartmentTypeOccurance, setPieChartDepartmentTypeOccurance] =
    useState([]);
  const [selectedFuelStations, setSelectedFuelStations] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);

    fetch(AdminGlobal.MYPROFILE, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setProfile(responseJson.user);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
    getMyDashboard(token);
  }, []);

  const getMyDashboard = (user_token) => {
    fetch(AdminGlobal.MYDASHBOARD, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user_token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let colors_array = [];
        let result1 = [];
        let result2 = [];
        let result3 = [];
        let estimate = [];

        let fuel_stations = [];

        let category_occurance = [];
        let department_type_occurance = [];
        setDashboardData(responseJson);
        responseJson?.per_fuel_station_expense.map((fuel_station) => {
          result1.push(fuel_station?.fuel_station?.name);
          estimate.push(fuel_station?.estimate_sum);
          colors_array.push("#" + Math.random().toString(16).substr(-6));

          fuel_stations.push({
            value: fuel_station?.fuel_station?.id,
            label: fuel_station?.fuel_station?.name,
          });
        });
        responseJson?.category_occurance.map((category) => {
          result2.push(category?.category?.category_name);
          category_occurance.push(category?.count);
        });
        responseJson?.department_type_occurance.map((department_type) => {
          result3.push(department_type?.department_type?.name);
          department_type_occurance.push(department_type?.count);
        });

        setFuelStationNameLabels(result1);
        setCategoryNameLabels(result2);
        setDepartmentTypeLabels(result3);

        setPieChartColors(colors_array);

        setFuelStations(fuel_stations);

        setPieChartEstimateOfExpense(estimate);
        setPieChartCategoryOccurance(category_occurance);
        setPieChartDepartmentTypeOccurance(department_type_occurance);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  const dataOfExpense = {
    responsive: true,
    labels: fuelStationNameLabels,
    datasets: [
      {
        label: "SAR",
        data: pieChartEstimatesOfExpense,
        backgroundColor: pieChartColors,
        borderColor: pieChartColors,
        borderWidth: 1,
      },
    ],
  };

  const dataOfCategoryOccurance = {
    responsive: true,
    labels: categoryNameLabels,
    datasets: [
      {
        label: "Occurance",
        data: pieChartCategoryOccurance,
        backgroundColor: pieChartColors,
        borderColor: pieChartColors,
        borderWidth: 1,
      },
    ],
  };

  const dataOfDepartmentTypeOccurance = {
    responsive: true,
    labels: departmentTypeLabels,
    datasets: [
      {
        label: "Occurance",
        data: pieChartDepartmentTypeOccurance,
        backgroundColor: pieChartColors,
        borderColor: pieChartColors,
        borderWidth: 1,
      },
    ],
  };

  const filterDashboard = () => {
    if (selectedFuelStations.length == 0) {
      toast.error("Please select at least one fuel station", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (startDate == null || startDate == "") {
      toast.error("Start Date should not be empty", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (endDate == null || endDate == "") {
      toast.error("End Date should not be empty", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (Date.parse(startDate) > Date.parse(endDate)) {
      toast.error("Start Date should not be greater than End Date", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    let fuel_station_ids = selectedFuelStations.map((item) => item.value);

    fetch(AdminGlobal.MYDASHBOARD, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fuel_station_ids: fuel_station_ids,
        start_date: startDate,
        end_date: endDate,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);

        let colors_array = [];
        let result1 = [];
        let result2 = [];
        let result3 = [];
        let estimate = [];

        let category_occurance = [];
        let department_type_occurance = [];
        setDashboardData(responseJson);
        responseJson?.per_fuel_station_expense.map((fuel_station) => {
          result1.push(fuel_station?.fuel_station?.name);
          estimate.push(fuel_station?.estimate_sum);
          colors_array.push("#" + Math.random().toString(16).substr(-6));
        });
        responseJson?.category_occurance.map((category) => {
          result2.push(category?.category?.category_name);
          category_occurance.push(category?.count);
          colors_array.push("#" + Math.random().toString(16).substr(-6));
        });
        responseJson?.department_type_occurance.map((department_type) => {
          result3.push(department_type?.department_type?.name);
          department_type_occurance.push(department_type?.count);
          colors_array.push("#" + Math.random().toString(16).substr(-6));
        });

        setFuelStationNameLabels(result1);
        setCategoryNameLabels(result2);
        setDepartmentTypeLabels(result3);

        setPieChartColors(colors_array);

        setPieChartEstimateOfExpense(estimate);
        setPieChartCategoryOccurance(category_occurance);
        setPieChartDepartmentTypeOccurance(department_type_occurance);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  return (
    <div id="main" style={{ fontFamily: "Nunito" }}>
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <div className="row">
        <header className="mb-3">
          <a href="#" className="burger-btn d-block d-xl-none">
            <i className="bi bi-justify fs-3" />
          </a>
        </header>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card" style={{ border: "solid 0px white" }}>
            <div className="card-body">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h3>Dashboard</h3>
                <h6
                  style={{
                    // paddingTop: "1%",
                    padding: "1%",
                    borderRadius: 10,
                    border: "1px solid #245692",
                    verticalAlign: "center",
                  }}
                >
                  Welcome, {profile?.first_name} {profile?.last_name}
                </h6>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                  paddingBottom: "1%",
                }}
              >
                <div className="form-group" style={{ paddingRight: "1%" }}>
                  <label htmlFor="basicInput">Fuel Station</label>
                  <Select
                    placeholder="Fuel Station"
                    onChange={(selected) => {
                      setSelectedFuelStations(selected);
                    }}
                    isMulti={true}
                    options={fuelStations}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                  />
                </div>
                <div className="form-group" style={{ paddingRight: "1%" }}>
                  <label htmlFor="basicInput">Start Date</label>
                  <input
                    placeholder="hi"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    type="date"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group" style={{ paddingRight: "1%" }}>
                  <label htmlFor="basicInput">End Date</label>
                  <input
                    placeholder="hi"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    type="date"
                    className="form-control"
                    required
                  />
                </div>
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    marginTop: "1.5%",
                    marginRight: "1%",
                  }}
                  onClick={() => {
                    filterDashboard();
                  }}
                >
                  <i className="bi bi-filter" style={{ marginTop: -10 }} />
                  Filter
                </Button>
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    marginTop: "1.5%",
                  }}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reset
                </Button>
              </div>
              <div
                style={{
                  width: "100%",
                  border: "1px solid #245692",
                  marginBottom: "1%",
                }}
              />
              <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                {dashboardData ? (
                  <>
                    <div
                      className="card text-white mb-3"
                      style={{
                        maxWidth: "13vw",
                        backgroundColor: colors.primary_blue,
                        border: "1px solid black",
                      }}
                    >
                      <div className="card-body">
                        <h5 className="card-title text-white center">
                          All Complaints
                        </h5>
                        <p
                          className="card-text text-white"
                          style={{ color: "black" }}
                        >
                          {dashboardData?.all_complaints}
                        </p>
                      </div>
                    </div>
                    <div
                      className="card text-white mb-3"
                      style={{
                        maxWidth: "13vw",
                        backgroundColor: colors.primary_blue,
                        border: "1px solid black",
                      }}
                    >
                      <div className="card-body">
                        <h5 className="card-title text-white center">
                          Pending Complaints
                        </h5>
                        <p
                          className="card-text text-white"
                          style={{ color: "black" }}
                        >
                          {dashboardData?.pending_complaints}
                        </p>
                      </div>
                    </div>
                    <div
                      className="card text-white mb-3"
                      style={{
                        maxWidth: "13vw",
                        backgroundColor: colors.primary_blue,
                        border: "1px solid black",
                      }}
                    >
                      <div className="card-body">
                        <h5 className="card-title text-white center">
                          Approved Complaints
                        </h5>
                        <p
                          className="card-text text-white"
                          style={{ color: "black" }}
                        >
                          {dashboardData?.approved_complaints}
                        </p>
                      </div>
                    </div>
                    <div
                      className="card text-white mb-3"
                      style={{
                        maxWidth: "13vw",
                        backgroundColor: colors.primary_blue,
                        border: "1px solid black",
                      }}
                    >
                      <div className="card-body">
                        <h5 className="card-title text-white center">
                          Verified Complaints
                        </h5>
                        <p
                          className="card-text text-white"
                          style={{ color: "black" }}
                        >
                          {dashboardData?.verified_complaints}
                        </p>
                      </div>
                    </div>
                    <div
                      className="card text-white mb-3"
                      style={{
                        maxWidth: "13vw",
                        backgroundColor: colors.primary_blue,
                        border: "1px solid black",
                      }}
                    >
                      <div className="card-body">
                        <h5 className="card-title text-white center">
                          Declined Complaints
                        </h5>
                        <p
                          className="card-text text-white"
                          style={{ color: "black" }}
                        >
                          {dashboardData?.declined_complaints}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <Loader />
                )}
              </div>

              <div className="row">
                <div className="col-md-4 p-4 text-center">
                  {fuelStationNameLabels.length > 0 ? (
                    <>
                      <h5>Expense per fuel station</h5>
                      <Pie data={dataOfExpense} />
                    </>
                  ) : null}
                </div>
                <div className="col-md-4 p-4 text-center">
                  {categoryNameLabels.length > 0 ? (
                    <>
                      <h5>Number of complaints per category</h5>
                      <Pie data={dataOfCategoryOccurance} />
                    </>
                  ) : null}
                </div>
                <div className="col-md-4 p-4 text-center">
                  {departmentTypeLabels.length > 0 ? (
                    <>
                      <h5>Department Type Complaints</h5>
                      <Pie data={dataOfDepartmentTypeOccurance} />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
  );
};

export default Dashboard;
