import { React, useState, useEffect, Fragment, createRef } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { GeneralGlobal } from "../APIs/GeneralGlobal";
import { colors } from "../Helpers/ColorsPalette";
import { Helmet } from "react-helmet";
import { AdminGlobal, project_type } from "../APIs/AdminGlobal";
import { Alert } from "bootstrap";
import Modal from "react-responsive-modal";
import "../Helpers/customModalcss.css";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [keeplogin, setKeeplogin] = useState(false);
  let form = createRef();

  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 646);
    if (location.state?.verified == 1) {
      toast.success("Account validated. You can now login", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    if (location.state?.passwordReset == 1) {
      toast.success("Password reset successfully. Please login to continue", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    if (location.state?.passwordReset == 0) {
      toast.error(
        "Seems like you already used your token to reset password. Please retry the process again.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }

    history.replace();
  }, []);

  const onsubmit = (values) => {
    if (values.email == null || values.email == "") {
      toast.error("Please enter email!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (values.password == null || values.password == "") {
      toast.error("Please enter password!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    setLoading(true);
    let formData = new FormData();
    fetch(GeneralGlobal.LOGIN, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: values.email,
        password: values.password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false);
        if (responseJson.status == 200) {
          localStorage.setItem("project_name", "quickpower");
          localStorage.setItem("token", responseJson.token);
          localStorage.setItem("user", JSON.stringify(responseJson.user));
          history.push("/dashboard");
          history.go(0);
        } else if (responseJson.status == 204) {
          toast.error(responseJson.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.info(responseJson.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  };

  const errors = {
    textAlign: "right",
    fontSize: "0.8vw",
    color: "red",
    fontWeight: "bold",
    borderColor: "red",
    borderRadius: 8,
    marginTop: "-15px",
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().email("Email is not valid").required("Required!"),
    password: Yup.string().required("Required!"),
  });

  return (
    <div className="content" style={{ display: "flex", height: "100vh" }}>
      <ToastContainer />
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="row" style={{ display: "flex", flexDirection: "row" }}>
          <div className="col-md-6" style={{ alignSelf: "center" }}>
            <img
              src={process.env.PUBLIC_URL + "/test2.png"}
              alt="Image"
              className="img-fluid"
              style={{ borderRadius: 10, width: "100%" }}
            />
            <div style={{ textAlign: "center" }}>
              <h1 style={{ color: colors.primary_blue }}>
                FuelStation Maintenance App
              </h1>
            </div>
          </div>
          <div className="col-md-6 contents">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="mb-4">
                  <h3>Sign In</h3>
                  <p className="mb-4"></p>
                </div>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={loginSchema}
                  onSubmit={(values) => {
                    onsubmit(values);
                  }}
                >
                  {(props) => (
                    <form action="#" method="post">
                      <div className="form-group first">
                        <label htmlFor="username">Email</label>
                        <input
                          type="email"
                          onChange={props.handleChange("email")}
                          className="form-control"
                          id="username"
                        />
                        {props.touched.email && props.errors.email ? (
                          <div style={errors}>
                            {props.touched.email && props.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group last mb-4">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          onChange={props.handleChange("password")}
                          className="form-control"
                          id="password"
                        />
                        {props.touched.password && props.errors.password ? (
                          <div style={errors}>
                            {props.touched.password && props.errors.password}
                          </div>
                        ) : null}
                      </div>
                      <div className="d-flex mb-5 align-items-center">
                        <label className="control control--checkbox mb-0">
                          {/* <span className="caption">Remember me</span> */}
                          {/* <input onche type="checkbox" defaultChecked="checked" /> */}
                          <div className="control__indicator" />
                        </label>
                        <span className="ml-auto">
                          <a href="#" className="forgot-pass">
                            Forgot Password
                          </a>
                        </span>
                      </div>
                      {loading == true ? (
                        <button
                          type="submit"
                          onClick={(e) => e.preventDefault()}
                          defaultValue="Log In"
                          style={{
                            backgroundColor: colors.primary_blue,
                            marginLeft: "0",
                          }}
                          className="btn btn-block btn-primary"
                        >
                          <Loader
                            color={colors.primary_background}
                            height={35}
                            width={35}
                            type={"Oval"}
                          />
                        </button>
                      ) : (
                        <input
                          type="submit"
                          onClick={props.handleSubmit}
                          defaultValue="Log In"
                          style={{
                            backgroundColor: colors.primary_blue,
                            marginLeft: "0",
                          }}
                          className="btn btn-block btn-primary"
                        />
                      )}

                      <span className="d-block text-left my-4 text-muted"></span>
                      <div className="social-login">
                        <a href="#" className="facebook">
                          <span className="icon-facebook mr-3" />
                        </a>
                        <a href="#" className="twitter">
                          <span className="icon-twitter mr-3" />
                        </a>
                        <a href="#" className="google">
                          <span className="icon-google mr-3" />
                        </a>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
