import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../Loader/Loader";
import * as Yup from "yup";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { colors } from "../../Helpers/ColorsPalette";
import ProgressLoader from "react-loader-spinner";
import { Helmet } from "react-helmet";

const EditDepartmentType = () => {
  let location = useLocation();
  let history = useHistory();
  const [token, setToken] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [loading, setloading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [responseJson, setResponseJson] = useState(null);

  const [allStatuses, setAllStatuses] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken("Bearer " + token);

    fetch(AdminGlobal.GETDEPARTMENTTYPEBYID + location.state.id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          setResponseJson(responseJson.data);
          setSelectedStatus({
            value: responseJson.data?.status_info?.id,
            label: responseJson.data?.status_info?.name,
          });
        }
        setloading(false);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
    fetch(AdminGlobal.GETALLSTATUSES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setAllStatuses(options);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  }, []);

  const errors = {
    textAlign: "right",
    fontSize: 14,
    color: "red",
    fontWeight: "bold",
    borderTopWidth: 0.8,
    borderColor: "red",
    borderRadius: 8,
  };

  const SaveDepartmentType = (values) => {
    setSubmitLoader(true);
    fetch(AdminGlobal.EDITDEPARTMENTTYPE + location.state.id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        department_type_name: values.name,
        status_info: { id: selectedStatus.value },
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          toast.success("Saved!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            history.push("/departmentType");
          }, 2000);
        } else {
          toast.error("There seems to be an error!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setSubmitLoader(false);
      })
      .catch((error) => {
        //Hide Loader
        setSubmitLoader(false);
        console.error(error);
      });
  };

  return (
    <div id="main" style={{ fontFamily: "Nunito" }}>
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <div className="row">
        <div
          style={{
            backgroundColor: "rgb(240, 240, 240,0.2)",
            color: colors.primary_blue,
          }}
          className="card-header card-header-rose card-header-text my-4"
        >
          <div className="card-text">
            <h4 className="card-title boldHeading">Edit Department Type</h4>
          </div>
        </div>
        <div className="col-md-12">
          <div
            className="card"
            style={{ border: "solid 0px white", borderRadius: "10px" }}
          >
            {responseJson != null ? (
              <div className="card-body ">
                <Formik
                  initialValues={{
                    name: responseJson.name,
                  }}
                  onSubmit={(values) => {
                    SaveDepartmentType(values);
                  }}
                >
                  {(props) => (
                    <form
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          e.preventDefault();
                        }
                      }}
                      className="form-horizontal"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Department Type Name{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("name")}
                              type="tel"
                              defaultValue={props.values.name}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          {selectedStatus != null ? (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label htmlFor="basicInput">
                                    Status <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <Select
                                    placeholder="Select Status"
                                    onChange={(selected) => {
                                      setSelectedStatus(selected);
                                    }}
                                    defaultValue={selectedStatus}
                                    options={allStatuses}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                          }}
                          className="btn btn-gh rounded-pill"
                          onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                          }}
                        >
                          Back
                        </button>
                        <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                            marginLeft: 5,
                          }}
                          type="submit"
                          className="btn btn-fill btn-gh rounded-pill"
                          // onClick={props.handleSubmit}
                          onClick={(e) => {
                            e.preventDefault();
                            props.handleSubmit();
                          }}
                        >
                          {submitLoader == true ? (
                            <ProgressLoader
                              color={"white"}
                              height={50}
                              width={50}
                              type={"Oval"}
                            />
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDepartmentType;
