import React, { useState, useEffect } from "react";
import { colors } from "../../Helpers/ColorsPalette";
import DataTable, { defaultThemes } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import Loader from "../../Loader/Loader";
import ShowParticipantsModal from "../../Helpers/showParticipantsModal";
import { Modal } from "react-responsive-modal";
import "../../Helpers/customModalcss.css";
import { Helmet } from "react-helmet";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import FileSaver, { saveAs } from "file-saver";
import { Button } from "react-bootstrap";
import Moment from "react-moment";
import "moment-timezone";

function ComplaintsReport() {
  let history = useHistory();
  const [user, setUser] = useState("");
  const userString = localStorage.getItem("user");
  const userObject = JSON.parse(userString);
  const [allComplaints, setAllComplaints] = useState([]);
  const [filteredComplaints, setFilteredComplaints] = useState([]);
  const [token, setToken] = useState(null);
  const [loading, setloading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const cuser = localStorage.getItem("user");
  let currentUser = JSON.parse(cuser);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  useEffect(() => {
    setUser(userObject);
    console.log({ userObject });
  }, []);
  useEffect(() => {
    setUser(userObject);
    const token = localStorage.getItem("token");
    setToken("Bearer " + token);
    setloading(true);

    fetch(AdminGlobal.GETALLCOMPLAINTS, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          setAllComplaints(responseJson.data);
          setFilteredComplaints(responseJson.data);
        }
        setloading(false);
      })
      .catch((error) => {
        //Hide Loader
        setloading(false);
        console.error(error);
      });
  }, []);
  const customStyles = {
    headRow: {
      style: {
        border: "none",
        borderRadius: 10,
        paddingTop: 20,
        paddingBottom: 30,
      },
    },
    table: {
      style: {
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    responsiveWrapper: {
      style: {
        backgroundColor: "#f1f1f1",
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    tableWrapper: {
      style: {
        backgroundColor: "#f1f1f1",
        border: "2px solid white",
        "border-top-left-radius": "25px",
        "border-top-right-radius": "25px",
      },
    },
    cells: {
      style: {
        display: "flex",
        "flex-wrap": "wrap",
        "justify-content": "center",
        border: "1px solid white",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
        display: "flex",
        "flex-wrap": "wrap",
        "justify-content": "center",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        ackgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        marginTop: 2,
        border: "2px solid white",
        "border-bottom-left-radius": "25px",
        "border-bottom-right-radius": "25px",
      },
    },
  };
  const columns = [
    {
      name: "Sr. No",
      sortable: true,
      selector: (row, index) => index + 1,
    },
    {
      name: "Complaint ID",
      sortable: true,
      selector: (row) => row.id,
    },
    {
      name: "Category",
      sortable: true,
      selector: (row) => row.category.category_name,
    },
    {
      name: "Subject",
      sortable: true,
      selector: (row) => row.subject,
    },
    {
      name: "Complaint Status",
      sortable: true,
      selector: (row) => row.complaint_status.name,
    },
    {
      name: "Fuel Station",
      sortable: true,
      selector: (row) => row.fuel_station.name,
    },
    {
      name: "Initiated On",
      sortable: true,
      selector: (row) => <Moment format="DD/MM/YYYY">{row.created_at}</Moment>,
    },
  ];

  const exportPDF = (arrayData) => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "All Complaints Report";
    const headers = [
      [
        "Sr. No",
        "Complaint ID",
        "Category",
        "Subject",
        "Complaint Status",
        "Fuel Station",
      ],
    ];
    const data = arrayData.map((item, index) => [
      index + 1,
      item.id,
      item.category.category_name,
      item.subject,
      item.complaint_status.name,
      item.fuel_station.name,
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("ComplaintsReport.pdf");
  };
  const exportToExcel = (csvData, fileName) => {
    let requriedData = [];
    let participants = [];
    let all_participants = [];
    csvData.map((row, index) => {
      requriedData.push({
        "Sr. No": index + 1,
        "Complaint ID": row.id,
        Category: row.category.category_name,
        Subject: row.subject,
        "Complaint Status": row.complaint_status.name,
        "Fuel Station": row.fuel_station.name,
      });
    });
    const ws = XLSX.utils.json_to_sheet(requriedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const filter = () => {
    let filtered = allComplaints.filter((complaint) => {
      let complaintDate = new Date(complaint["created_at"]);
      let start = new Date(startDate);
      let end = new Date(endDate);
      return complaintDate >= start && complaintDate <= end;
    });
    console.log("filtered complaints are: ", filtered);
    // console.log("all complaints are: ", allComplaints);
    setFilteredComplaints(filtered);
  };

  return (
    <div id="main">
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <header className="mb-3">
        <a href="#" className="burger-btn d-block d-xl-none">
          <i className="bi bi-justify fs-3" />
        </a>
      </header>
      <div className="page-heading">
        <div className="page-title">
          <div className="row">
            <div className="col-12 col-md-6 order-md-1 order-last">
              <h3>Complaints Report</h3>
              <p className="text-subtitle text-muted"></p>
              <p className="text-subtitle text-muted"></p>
              <p className="text-subtitle text-muted"></p>
            </div>
            <div className="col-12 col-md-6 order-md-2 order-first">
              <nav
                aria-label="breadcrumb"
                className="breadcrumb-header float-start float-lg-end"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  {currentUser?.granted_permissions_array.indexOf(
                    "report_view"
                  ) >
                    -1 ===
                  true ? (
                    <>
                      <button
                        style={{
                          marginRight: 5,
                          backgroundColor: colors.primary_blue,
                          alignItems: "center",
                          justifyContent: "center",
                          borderColor: colors.primary_blue,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          exportToExcel(filteredComplaints, "ComplaintsReport");
                        }}
                        type="button"
                        rel="tooltip"
                        className="btn btn-primary"
                      >
                        Export to Excel
                        <i
                          style={{ marginLeft: 5 }}
                          className="bi bi-download"
                        ></i>
                      </button>
                      <button
                        style={{
                          marginRight: 5,
                          backgroundColor: colors.primary_blue,
                          alignItems: "center",
                          justifyContent: "center",
                          borderColor: colors.primary_blue,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          exportPDF(filteredComplaints);
                        }}
                        type="button"
                        rel="tooltip"
                        className="btn btn-primary"
                      >
                        Export to PDF
                        <i
                          style={{ marginLeft: 5 }}
                          className="bi bi-download"
                        ></i>
                      </button>
                    </>
                  ) : null}
                  <div className="form-group" style={{ paddingRight: "1%" }}>
                    <input
                      placeholder="hi"
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                      type="date"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group" style={{ paddingRight: "1%" }}>
                    <input
                      placeholder="hi"
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                      type="date"
                      className="form-control"
                      required
                    />
                  </div>
                  <Button
                    style={{ marginRight: "1%" }}
                    onClick={() => {
                      filter();
                    }}
                  >
                    <i className="bi bi-filter" style={{ marginTop: -10 }} />
                    Filter
                  </Button>
                  <Button
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <DataTable
          pagination
          paginationRowsPerPageOptions={[10, 30, 50, 70, 100]}
          columns={columns}
          data={filteredComplaints}
          paginationPerPage={100}
          progressPending={loading}
          progressComponent={<Loader />}
          striped
          dense
          customStyles={customStyles}
        />
      </div>
      <footer></footer>
    </div>
  );
}
export default ComplaintsReport;
