import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../Loader/Loader";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { colors } from "../../Helpers/ColorsPalette";
import ProgressLoader from "react-loader-spinner";
import { Helmet } from "react-helmet";

const EditUser = () => {
  let history = useHistory();
  let location = useLocation();
  const [token, setToken] = useState(null);
  const [loading, setloading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [allStatuses, setAllStatuses] = useState([]);
  const [selectedstatus, setselectedStatus] = useState(null);
  const [responseJson, setResponseJson] = useState(null);
  const [dob, setDob] = useState(null);
  const [safety, setSafety] = useState(0);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [departmentTypes, setDepartmentTypes] = useState([]);
  const [selectedDepartmentType, setSelectedDepartmentType] = useState(null);
  const [fuelStations, setFuelStations] = useState([]);
  const [selectedFuelStation, setSelectedFuelStation] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [selectedGender, setSelectedGender] = useState({});
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [togglePasswordEdit, setTogglePasswordEdit] = useState(false);

  const gender = [
    {
      value: 1,
      label: "Male",
    },
    {
      value: 2,
      label: "Female",
    },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken("Bearer " + token);

    fetch(AdminGlobal.GETALLCITIES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setAllCities(options);
      })
      .catch((error) => {
        //Hide Loader
        // console.error(error);
      });

    fetch(AdminGlobal.GETALLSTATUSES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setAllStatuses(options);
      })
      .catch((error) => {
        //Hide Loader
        // console.error(error);
      });

    fetch(AdminGlobal.GETALLUSERTYPES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setUserTypes(options);
      })
      .catch((error) => {
        //Hide Loader
        // console.error(error);
      });

    fetch(AdminGlobal.GETALLDEPARTMENTTYPES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setDepartmentTypes(options);
      })
      .catch((error) => {
        //Hide Loader
        // console.error(error);
      });

    fetch(AdminGlobal.GETALLFUELSTATIONS, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setFuelStations(options);
      })
      .catch((error) => {
        //Hide Loader
        // console.error(error);
      });

    fetch(AdminGlobal.GETALLROLES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.roles.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setAllRoles(options);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });

    fetch(AdminGlobal.GETUSERBYID + location.state.id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          setResponseJson(responseJson.data);
          setSelectedUserType({
            value: responseJson.data?.user_type?.id,
            label: responseJson.data?.user_type?.name,
          });
          setselectedStatus({
            value: responseJson.data?.status_info?.id,
            label: responseJson.data?.status_info?.name,
          });
          setDob(responseJson.data?.user_info?.date_of_birth);
          setSelectedCity({
            value: responseJson.data?.user_info?.city?.id,
            label: responseJson.data?.user_info?.city?.name,
          });
          if (responseJson.data?.user_info?.gender == "Male") {
            setSelectedGender({
              value: 1,
              label: responseJson.data?.user_info?.gender,
            });
          } else {
            setSelectedGender({
              value: 2,
              label: responseJson.data?.user_info?.gender,
            });
          }

          if (responseJson.data.user_type_id == 1) {
            setSelectedRole({
              value: responseJson.data?.roles[0]?.id,
              label: responseJson.data?.roles[0]?.name,
            });
          }

          if (responseJson.data.user_type_id == 3) {
            setSelectedDepartmentType({
              value: responseJson.data?.user_info?.department_type?.id,
              label: responseJson.data?.user_info?.department_type?.name,
            });

            let arr1 = [];
            responseJson.data?.department_type.map((val) => {
              arr1.push({
                value: val.id,
                label: val.name,
              });
            });
            setSelectedDepartmentType(arr1);
          } else if (responseJson.data.user_type_id == 4) {
            setSelectedDepartmentType({
              value: responseJson.data?.user_info?.department_type?.id,
              label: responseJson.data?.user_info?.department_type?.name,
            });

            let arr1 = [];
            responseJson.data?.department_type.map((val) => {
              arr1.push({
                value: val.id,
                label: val.name,
              });
            });
            setSelectedDepartmentType(arr1);

            if (
              responseJson.data?.fuel_stations != undefined ||
              responseJson.data?.fuel_stations != null
            ) {
              let arr = [];
              responseJson.data?.fuel_stations.map((val) => {
                arr.push({
                  value: val.id,
                  label: val.name,
                });
              });
              setSelectedFuelStation(arr);
            }
          }
        }
      })
      .catch((error) => {
        //Hide Loader
        // console.error(error);
      });
  }, []);

  const errors = {
    textAlign: "right",
    fontSize: 14,
    color: "red",
    fontWeight: "bold",
    borderTopWidth: 0.8,
    borderColor: "red",
    borderRadius: 8,
  };

  const editUser = (values) => {
    setSubmitLoader(true);

    if (
      togglePasswordEdit === true &&
      (values.password == null || values.password == "")
    ) {
      toast.error("Please provide a password!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitLoader(false);
      return;
    }

    if (selectedUserType.value == 1 && selectedRole.value == null) {
      toast.error("Please select a role!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    fetch(AdminGlobal.EDITUSER + location.state.id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: values.first_name,
        last_name: values.last_name,
        user_name: values.user_name,
        email: values.email,
        address: values.address,
        date_of_birth: dob,
        password: values.password,
        contact_number: values.mobile_number,
        gender: selectedGender,
        nationality_city_id: selectedCity.value,
        user_type: { id: selectedUserType.value },
        department_types: selectedDepartmentType,
        fuel_stations: selectedFuelStation,
        selectedRole: selectedRole.value,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          toast.success("Saved!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            history.push("/users");
          }, 2000);
        } else {
          toast.error("There seems to be an error!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setSubmitLoader(false);
      })
      .catch((error) => {
        //Hide Loader
        setSubmitLoader(false);
        // console.error(error);
      });
  };

  return (
    <div id="main" style={{ fontFamily: "Nunito" }}>
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <div className="row">
        <div
          style={{
            backgroundColor: "rgb(240, 240, 240,0.2)",
            color: colors.primary_blue,
          }}
          className="card-header card-header-rose card-header-text my-4"
        >
          <div className="card-text">
            <h4 className="card-title boldHeading">Edit User</h4>
          </div>
        </div>
        <div className="col-md-12">
          <div
            className="card"
            style={{ border: "solid 0px white", borderRadius: "10px" }}
          >
            {responseJson != null ? (
              <div className="card-body ">
                <Formik
                  initialValues={{
                    first_name: responseJson.first_name,
                    last_name: responseJson.last_name,
                    gender: responseJson?.user_info?.gender,
                    user_name: responseJson.user_name,
                    mobile_number: responseJson?.user_info?.contact_number,
                    email: responseJson.email,
                    address: responseJson?.user_info?.address,
                  }}
                  onSubmit={(values) => {
                    editUser(values);
                  }}
                >
                  {(props) => (
                    <form
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          e.preventDefault();
                        }
                      }}
                      className="form-horizontal"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              First Name <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("first_name")}
                              type="tel"
                              defaultValue={props.values.first_name}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Last Name <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("last_name")}
                              type="tel"
                              defaultValue={props.values.last_name}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Gender <b style={{ color: "red" }}>*</b>
                            </label>
                            <Select
                              placeholder="Select Gender"
                              onChange={(selected) => {
                                setSelectedGender(selected);
                              }}
                              defaultValue={selectedGender}
                              isDisabled={gender.length > 0 ? false : true}
                              options={gender}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            {gender == null && submitted == true ? (
                              <div style={errors}>Required</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Username <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("user_name")}
                              type="tel"
                              defaultValue={props.values.user_name}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Mobile No <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("mobile_number")}
                              type="tel"
                              defaultValue={props.values.mobile_number}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Email <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("email")}
                              type="email"
                              defaultValue={props.values.email}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {dob != "" ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                Date of Birth <b style={{ color: "red" }}>*</b>
                              </label>
                              <input
                                onChange={(e) => setDob(e.target.value)}
                                type="date"
                                defaultValue={dob}
                                className="form-control"
                                required
                              />
                            </div>
                          </div>
                        ) : null}
                        {selectedCity != null ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                City <b style={{ color: "red" }}>*</b>
                              </label>
                              <Select
                                placeholder="Select City"
                                onChange={(selected) => {
                                  setSelectedCity(selected);
                                }}
                                defaultValue={selectedCity}
                                options={allCities}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Address <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("address")}
                              type="tel"
                              defaultValue={props.values.address}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      {selectedstatus != null ? (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                Status <b style={{ color: "red" }}>*</b>
                              </label>
                              <Select
                                placeholder="Select Status"
                                onChange={(selected) => {
                                  setselectedStatus(selected);
                                }}
                                defaultValue={selectedstatus}
                                options={allStatuses}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </div>
                          </div>
                          {userTypes.length > 0 ? (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="basicInput">
                                  User Type <b style={{ color: "red" }}>*</b>
                                </label>
                                <Select
                                  // isDisabled={true}
                                  placeholder="Select User Type"
                                  defaultValue={selectedUserType}
                                  value={selectedUserType}
                                  onChange={(selected) => {
                                    setSelectedUserType(selected);
                                    if (selected.value != 4) {
                                      setSelectedFuelStation([]);
                                    }
                                    if (selected.value == 1) {
                                      setSelectedFuelStation([]);
                                      setSelectedDepartmentType([]);
                                    }
                                  }}
                                  options={userTypes}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                                {selectedUserType == null &&
                                submitted == true ? (
                                  <div style={errors}>Required</div>
                                ) : null}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}

                      <div className="row">
                        {selectedUserType?.value == 4 ||
                        selectedFuelStation.length > 0 ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                Branches <b style={{ color: "red" }}>*</b>
                              </label>
                              <Select
                                placeholder="Assign Branches"
                                onChange={(selected) => {
                                  setSelectedFuelStation(selected);
                                }}
                                isDisabled={
                                  fuelStations.length > 0 ? false : true
                                }
                                defaultValue={selectedFuelStation}
                                isMulti={true}
                                options={fuelStations}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                              {selectedFuelStation == null &&
                              submitted == true ? (
                                <div style={errors}>Required</div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}

                        {selectedUserType?.value != 1 ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                Department Type{" "}
                                <b style={{ color: "red" }}>*</b>
                              </label>
                              <Select
                                placeholder="Select Department Type"
                                onChange={(selected) => {
                                  setSelectedDepartmentType(selected);
                                }}
                                isDisabled={
                                  departmentTypes.length > 0 ? false : true
                                }
                                defaultValue={selectedDepartmentType}
                                isMulti={true}
                                options={departmentTypes}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="col-md-6">
                              {/* {console.log(allRoles, selectedRole)} */}
                              <div className="form-group">
                                <label htmlFor="basicInput">
                                  Role
                                  <b style={{ color: "red" }}>*</b>
                                </label>
                                <Select
                                  placeholder="Select Role"
                                  onChange={(selected) => {
                                    setSelectedRole(selected);
                                  }}
                                  defaultValue={selectedRole}
                                  options={allRoles}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Password <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              type={"password"}
                              disabled={
                                togglePasswordEdit == true ? false : true
                              }
                              onChange={props.handleChange("password")}
                              className="form-control"
                            />
                          </div>
                          <button
                            style={{
                              backgroundColor: colors.primary_blue,
                              color: "white",
                              display:
                                togglePasswordEdit === true ? "none" : "flex",
                            }}
                            className="btn btn-gh rounded-pill"
                            onClick={(e) => {
                              e.preventDefault();
                              setTogglePasswordEdit(true);
                            }}
                          >
                            Make Password Editable
                          </button>
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                          }}
                          className="btn btn-gh rounded-pill"
                          onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                          }}
                        >
                          Back
                        </button>
                        <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                            marginLeft: 5,
                          }}
                          type="submit"
                          className="btn btn-fill btn-gh rounded-pill"
                          // onClick={props.handleSubmit}
                          onClick={props.handleSubmit}
                        >
                          {submitLoader == true ? (
                            <ProgressLoader
                              color={"white"}
                              height={50}
                              width={50}
                              type={"Oval"}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
