import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../Loader/Loader";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";
import { AdminGlobal } from "../../APIs/AdminGlobal";
import { colors } from "../../Helpers/ColorsPalette";
import ProgressLoader from "react-loader-spinner";
import { Helmet } from "react-helmet";

const ViewUser = () => {
  let history = useHistory();
  let location = useLocation();
  const [token, setToken] = useState(null);
  const [loading, setloading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [allStatuses, setAllStatuses] = useState([]);
  const [selectedstatus, setselectedStatus] = useState(null);
  const [responseJson, setResponseJson] = useState(null);
  const [dob, setDob] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken("Bearer " + token);

    fetch(AdminGlobal.GETALLCITIES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setAllCities(options);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });

    fetch(AdminGlobal.GETALLSTATUSES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const options = responseJson.data.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setAllStatuses(options);
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });

    fetch(AdminGlobal.GETUSERBYID + location.state.id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          setResponseJson(responseJson.data);
          setDob(responseJson.data.user_info.date_of_birth);
          setselectedStatus({
            value: responseJson.data.user_info.status_info.id,
            label: responseJson.data.user_info.status_info.name,
          });
          setSelectedCity({
            value: responseJson.data.user_info.city.id,
            label: responseJson.data.user_info.city.name,
          });
        }
      })
      .catch((error) => {
        //Hide Loader
        console.error(error);
      });
  }, []);

  const subAdminSchema = Yup.object({
    employee_name: Yup.string()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    user_name: Yup.string()
      .min(3, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid Email").required("Required"),
    mobile_number: Yup.string()
      .matches(
        "^((\\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$",
        "Phone number is not valid"
      )
      .max(11, "Invalid Number")
      .required("Required!"),
  });

  const errors = {
    textAlign: "right",
    fontSize: 14,
    color: "red",
    fontWeight: "bold",
    borderTopWidth: 0.8,
    borderColor: "red",
    borderRadius: 8,
  };

  const saveMainManager = (values) => {
    setSubmitLoader(true);
    fetch(AdminGlobal.ADDMAINOFFICEMANAGER, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: values.first_name,
        last_name: values.last_name,
        user_name: values.user_name,
        email: values.email,
        address: values.address,
        date_of_birth: dob,
        contact_number: values.mobile_number,
        gender: values.gender,
        city: { id: selectedCity.value },
        status_info: { id: selectedstatus.value },
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          toast.success("Saved!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            history.push("/mainmanagers");
          }, 2000);
        } else {
          toast.error("There seems to be an error!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setSubmitLoader(false);
      })
      .catch((error) => {
        //Hide Loader
        setSubmitLoader(false);
        console.error(error);
      });
  };

  return (
    <div id="main" style={{ fontFamily: "Nunito" }}>
      <Helmet>
        <link rel="stylesheet" href="assets/css/main/app.css" />
      </Helmet>
      <ToastContainer />
      <div className="row">
        <div
          style={{
            backgroundColor: "rgb(240, 240, 240,0.2)",
            color: colors.primary_blue,
          }}
          className="card-header card-header-rose card-header-text my-4"
        >
          <div className="card-text">
            <h4 className="card-title boldHeading">View User</h4>
          </div>
        </div>
        <div className="col-md-12">
          <div
            className="card"
            style={{ border: "solid 0px white", borderRadius: "10px" }}
          >
            {responseJson != null ? (
              <div className="card-body ">
                <Formik
                  initialValues={{
                    first_name: responseJson.first_name,
                    last_name: responseJson.last_name,
                    gender: responseJson.user_info.gender,
                    user_name: responseJson.user_name,
                    mobile_number: responseJson.user_info.contact_number,
                    email: responseJson.email,
                    address: responseJson.user_info.address,
                  }}
                  onSubmit={(values) => {
                    saveMainManager(values);
                  }}
                >
                  {(props) => (
                    <form
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          e.preventDefault();
                        }
                      }}
                      className="form-horizontal"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              First Name <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("first_name")}
                              type="tel"
                              disabled
                              defaultValue={props.values.first_name}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Last Name <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("last_name")}
                              type="tel"
                              disabled
                              defaultValue={props.values.last_name}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Gender <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("gender")}
                              type="tel"
                              disabled
                              defaultValue={props.values.gender}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Username <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("user_name")}
                              type="tel"
                              disabled
                              defaultValue={props.values.user_name}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Mobile No <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("mobile_number")}
                              type="tel"
                              disabled
                              defaultValue={props.values.mobile_number}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Email <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("email")}
                              type="email"
                              disabled
                              defaultValue={props.values.email}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {dob != "" ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                Date of Birth <b style={{ color: "red" }}>*</b>
                              </label>
                              <input
                                onChange={(e) => setDob(e.target.value)}
                                type="date"
                                disabled
                                defaultValue={dob}
                                className="form-control"
                                required
                              />
                            </div>
                          </div>
                        ) : null}
                        {selectedCity != null ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                City <b style={{ color: "red" }}>*</b>
                              </label>
                              <Select
                                placeholder="Select City"
                                onChange={(selected) => {
                                  setSelectedCity(selected);
                                }}
                                isDisabled
                                defaultValue={selectedCity}
                                options={allCities}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Address <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("address")}
                              type="tel"
                              disabled
                              defaultValue={props.values.address}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      {selectedstatus != null ? (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="basicInput">
                                Status <b style={{ color: "red" }}>*</b>
                              </label>
                              <Select
                                placeholder="Select City"
                                onChange={(selected) => {
                                  setselectedStatus(selected);
                                }}
                                isDisabled
                                defaultValue={selectedstatus}
                                options={allStatuses}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="basicInput">
                              Password <b style={{ color: "red" }}>*</b>
                            </label>
                            <input
                              onChange={props.handleChange("password")}
                              type="password"
                              autoComplete="new-password"
                              className="form-control"
                              
                            />
                          </div>
                        </div>
                      </div> */}

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          style={{
                            backgroundColor: colors.primary_blue,
                            color: "white",
                          }}
                          className="btn btn-gh rounded-pill"
                          onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
