export const project_type = "live";
var LiveServer = "https://backend.quickpower.sa/public/api/rootadmin/";
var TestingServer =
  "https://testingbackend.quickpower.sa/public/api/rootadmin/";
var link = project_type == "live" ? LiveServer : TestingServer;

export const AdminGlobal = {
  //category api
  GETALLCATEGORIES: link + "category",
  GETCATEGORYBYID: link + "category/",
  ADDCATEGORY: link + "category",
  EDITCATEGORY: link + "category/",
  DESTROYCATEGORY: link + "category/",

  // Fuel Station Manager Api
  GETALLFUELSTATIONMANAGETERS: link + "fuelStationManager",
  GETFUELSTATIONMANAGERBYID: link + "fuelStationManager/",
  ADDFUELSTATIONMANAGER: link + "fuelStationManager",
  EDITFUELSTATIONMANAGER: link + "fuelStationManager/",

  // MAIN OFFICE MANAGER API
  GETALLMAINOFFICEMANAGERS: link + "mainOfficeManager",
  GETMAINOFFICEMANAGERBYID: link + "mainOfficeManager/",
  ADDMAINOFFICEMANAGER: link + "mainOfficeManager",
  EDITMAINOFFICEMANAGER: link + "mainOfficeManager/",

  // Super Admin API
  GETALLSUPERADMINS: link + "superAdmin",
  GETSUPERADMINBYID: link + "superAdmin/",
  ADDSUPERADMIN: link + "superAdmin",
  EDITSUPERADMIN: link + "superAdmin/",

  // FUEL STATION API
  GETALLFUELSTATIONS: link + "fuelStation",
  GETFUELSTATIONBYID: link + "fuelStation/",
  ADDFUELSTATION: link + "fuelStation",
  EDITFUELSTATION: link + "fuelStation/",
  DESTROYFUELSTATION: link + "fuelStation/",

  // User Api
  GETALLUSERS: link + "user",
  GETUSERBYID: link + "user/",
  ADDUSER: link + "user",
  EDITUSER: link + "user/",
  DESTROYUSER: link + "user/",

  //status APIs
  GETALLSTATUSES: link + "status",
  UPDATEMAINOFFICEMANAGERSTATUS: link + "updateMainOfficeManagerStatus/",
  UPDATEFUELSTATIONMANAGERSTATUS: link + "updateFuelStationManagerStatus/",
  UPDATEFUELSTATIONSTATUS: link + "updateFuelStationStatus/",
  UPDATECATEGORYSTATUS: link + "updateCategoryStatus/",
  UPDATEUSERSTATUS: link + "updateUserStatus/",

  //City list api
  GETALLCITIES: "https://backend.quickpower.sa/public/api/city",

  // Reportings api
  GETCATEGORYREPORTS: link + "report/categoryComplaint",
  GETFUELSTATIIONEXPENSEREPORT: link + "report/fuelStationExpenses",
  GETCATEGORYEXPENSE: link + "report/categoryExpenses",

  // Complaints list
  GETALLCOMPLAINTS: link + "complaint",

  //User Types
  GETALLUSERTYPES: link + "userType",

  //Department Types
  GETALLDEPARTMENTTYPES: link + "departmentType",
  GETDEPARTMENTTYPEBYID: link + "departmentType/",
  EDITDEPARTMENTTYPE: link + "departmentType/",
  ADDDEPARTMENTTYPE: link + "departmentType",
  DESTROYDEPARTMENTTYPE: link + "departmentType/",

  GETALLCOMPLAINTS: link + "complaint",
  GETCOMPLAINTBYID: link + "complaint/",

  MYDASHBOARD: link + "dashboard",
  MYPROFILE: link + "profile",

  GETALLCOMPLAINTSTATUSES: link + "complaintStatus",
  GETALLCOMPLAINTTYPES: link + "complaintType",
  FILTERCOMPLAINT: link + "filterComplaint",

  GETALLROLES: link + "role",
  ROLEPREREQ: link + "roleForm",
  ADDROLE: link + "role",
  GETROLEBYID: link + "role/",
  EDITROLE: link + "role/",
};
